import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { LiteratureTypesAndGenresBlock } from '../../views/knowledge/knowledge';
import '../../../scripts-store/scripts.store';
import { ScriptService } from 'src/app/scripts-store/script.service';

declare var vis: any;

@Component({
  selector: 'app-literature-types-genre-block',
  templateUrl: './literature-types-genre-block.component.html',
  styleUrls: ['./literature-types-genre-block.component.scss'],
  providers: [ScriptService],
})
export class LiteratureTypesGenreBlockComponent implements OnInit {
  @ViewChild('siteConfigNetwork') networkContainer!: ElementRef;
  @ViewChildren('target')
  target!: ElementRef;

  private network: any;

  literatureTypesAndGenresBlock: LiteratureTypesAndGenresBlock[] = [
    {
      typeOrGenreTitle: 'DAIĻLITERATŪRA',
      text: '<span style="font-weight: bold;">Literatūra</span> plašākā nozīmē ir viss rakstītais. <br /> <span style="font-weight: bold;">Daiļliteratūras</span> definīcija dažādos avotos atšķirsies, bet vienojošais – <span style="font-weight: bold;">literatūra kā mākslas veids</span>, tās mērķis: būt mākslas darbam un ietekmēt lasītāju tā, kā to ietekmē jebkurš mākslas darbs – caur emocijām un līdzpārdzīvojumu. Daiļliteratūra ir vārda māksla.<br /> Uz daiļliteratūru attiecas un to ietekmē visi vēsturiskie mākslas virzieni no antīkās un viduslaiku mākslas, baroka, renesanses līdz postmodernismam. <br /> Daiļliteratūru iedala 4 veidos,  tos  nosaka nevis pēc satura, bet pēc formas. Visus veidus, izņemot dzeju, sīkāk iedala žanros.',
      isFiction: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk letonika.lv Dzejas vārdīcā:',
        readMoreLinks: [
          {
            linkLabel: 'literatūra',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32586',
          },
          {
            linkLabel: 'žanrs',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=33107',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'LIRIKA jeb DZEJA',
      text: '<span style="font-weight: bold;">Dzejas pamatpazīmes ir ritms un saistīta jeb vārsmota valoda. Dzejas tekstu raksturo arī panta forma/rindu lauzumi un atskaņas.</span> <br /> Dzejas pamatā ir autora pārdzīvojums, iekšējās pasaules izpausme. Dzejai raksturīgs bagātīgs tēlainās izteiksmes līdzekļu lietojums, valodas tēlainība. Katrs dzejolis ietver tikai spilgtāko no atsevišķas izjūtas. Katrs dzejolis ir viena vienība no krājuma. <br /> Tradicionāli dzeju sīkāk neiedala žanros, tomēr dzejas tematika un forma ļauj izšķirt, piemēram, odu, elēģiju, himnu, romanci, sonetu, haiku, akrostihu, epigrammu, veltījuma dzejoli, dzeju bērniem uc. <br /> Atšķirībā no liroepikas, dzejai parasti nav sižeta. <br /> Savukārt dzejproza jeb dzeja prozā parasti tiek definēta kā daiļdarbs, kurš daļēji atgādina dzejoli un daļēji prozu. Pazīmes, kas dzejoli prozā tuvina dzejai, ir izteikta sižeta trūkums, neliels apjoms, liriska tēlojuma iezīmes, paaugstināta emocionālā spriedze, subjektivitāte, savukārt ar prozu to sarado stingra metra un atskaņu trūkums, dalījums rindkopās.',
      isPoetry: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk letonika.lv Dzejas vārdnīcā:',
        readMoreLinks: [
          {
            linkLabel: 'lirika',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32580',
          },
          {
            linkLabel: 'dzeja, dzejolis',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32291',
          },
          {
            linkLabel: 'dzejproza',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32306',
          },
          {
            linkLabel: 'saistīta valoda',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32881',
          },
          {
            linkLabel: 'atskaņas',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=33025',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel:
          'Uzzināt vairāk un lasīt 51 dzejas krājumu letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'lirika',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2189831',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'EPIKA jeb PROZA',
      text: '<span style="font-weight: bold;">Epika (proza)  –  dažāda apjoma sižetiski darbi, kas rakstīti nesaistītā valodā.</span> <br/> Proza ir viens no daiļliteratūras pamatveidiem (blakus drāmai un lirikai), tai raksturīgs autora objektīvs vēstījums par notikumiem un personām.<br /> Stāsts, novele, romāns un literārā pasaka būs minēti visos avotos, tie ir seni, stabili prozas žanri, ar labi zināmām pazīmēm un definīcijām. <br /> Žanri, tādi kā miniatūra, epifānija, eseja, dažādos avotos var būt nosaukti, iedalīti un grupēti dažādi, bieži to robežas ir grūti definēt, ir daudz starpžanru darbu. Šis ir tikai viens no biežāk izmantotajiem dalījumiem. <br /> Ir vairāki literatūras žanri, kas atrodas uz robežas starp daiļliteratūru un publicistiku, un kurus arī bieži pieskaita prozai – mākslinieciskais apraksts, mākslinieciskā publicistika, dokumentālā un autobiogrāfiskā proza, epistulārais un dienasgrāmatu žanrs, humoreska un feļetons.',
      isProse: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk letonika.lv Dzejas vārdnīcā:',
        readMoreLinks: [
          {
            linkLabel: 'epika',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32351',
          },
          {
            linkLabel: 'dzeja un proza',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32295',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel:
          'Uzzināt vairāk un lasīt 64 prozas darbus un darbu izlases letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'epika',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2196333',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'ROMĀNS',
      text: '<span style="font-weight: bold;">Romāns ir plašs prozas darbs, kurā izvērsti atklātas atsevišķu cilvēku un sabiedrības dzīve ilgākā laika posmā, daudzpusīgi atsedzot galveno varoņu personību, izaugsmi, domu un jūtu pasauli.</span> <br /> Romāna notikumus un tēlus parasti vieno viens galvenais varonis. <br /> Romānu no stāsta atšķir plaši sazarotā tēlu sistēma, kā arī,  laikmeta  un varoņa attiecību ar sabiedrību un laikmetu atveidojums. <br /> Sadzīves, sociālajā, vēsturiskajā un biogrāfiskajā romānā parasti attēlota ikdienišķa vide, vienkārši cilvēki ar viņu kļūdām, varoņu personiskie pieredzējumi. Pastāv arī pasaku romāns, fantāzijas romāns, zinātniskās fantastikas romāns, piedzīvojumu romāns, spriedzes romāns u.c.<br /> Viduslaiku literatūrā samērā izplatīts bija tāds starpžanrs kā romāns pantos (dzejā). Mūsdienās sastopams reti (piem., Raiņa "Dagdas skiču burtnīcas").',
      isProseSubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk Nacionālajā enciklopēdijā:',
        readMoreLinks: [
          {
            linkLabel: '"Mērnieku laiki"',
            linkUrl:
              'https://enciklopedija.lv/skirklis/55739-%22M%C4%93rnieku-laiki%22',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel: 'Uzzināt vairāk un lasīt romānus letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'romāns',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2189835',
          },
          {
            linkLabel: 'Regīna Ezera “Aka”',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=277',
          },
          {
            linkLabel: 'Reinis un Matīss Kaudzītes “Mērnieku laiki”',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=160',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'STĀSTS',
      text: '<span style="font-weight: bold;">Stāsta (jeb īsā stāsta) vēstījuma centrā ir viens tēls un viens notikums, kas norobežots laikā (varoņa dzīve) un telpā.</span> Stāstā darbojas maz tēlu. Ir galvenais varonis, bet pārējie tēli tiek atklāti attieksmē pret galveno tēlu. Stāstā attēlota ikdiena. Apjoma ziņā stāsts ir īsāks nekā romāns. Atšķirībā no noveles, stāsta finālā nav negaidīta pavērsiena vai anekdotiska pārsteiguma.<br /> <span style="font-weight: bold;">Garstāsts pēc apjoma atrodas starp stāstu un romānu.</span> Garstāsta pazīme - hronikāls vēstījums, kurā īpaša vieta vēstītājam. Garstāstā tēlots tikai tas, kas saista stāstītāja uzmanību, pasaules daudzveidība atklājas ar viņa acīm. <br /> <span style="font-weight: bold;">Stāstu cikls ir stāstu kopums, kas veido saturisku veselumu.</span>',
      isProseSubtitle: true,
      readMore: {
        readMoreLabel: 'Uzzināt vairāk un lasīt stāstus letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'stāsts',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2189836',
          },
          {
            linkLabel: 'Jānis Poruks “Kauja pie Knipskas”',
            linkUrl:
              'https://letonika.lv/literatura/Reader.aspx?r=154#2191034/I:4/P:1',
          },
          {
            linkLabel: 'Regīna Ezera “Cilvēkam vajag suni”',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=278',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'NOVELE',
      text: '<span style="font-weight: bold;">Novelē attēlots spilgts notikums, kas strauji risinās, darbības norisē ir kulminācija, pavērsieni un negaidīts atrisinājums.</span> Novelē parasti attēlo tādu notikumu, kas satricina cilvēku ikdienu. Novele ir īsprozas darbs, kurā laika un telpas vienotība ir spilgi izteikta. Precīzais strukturējums to atšķir no īsā stāsta, kura uzbūve ir daudz brīvāka, kā arī no garstāsta un romāna, kuru sižeta apjoms parasti ir ievērojami plašāks. Novelē attēlotais notikums var būt gan komisks vai anekdotisks, gan dramatisks vai traģisks. Raksturu vai psiholoģiskā novele atklāj cilvēka personības īpatnības.',
      isProseSubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk Nacionālajā enciklopēdijā:',
        readMoreLinks: [
          {
            linkLabel: 'novele',
            linkUrl: 'https://enciklopedija.lv/skirklis/99972-novele',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel: 'Uzzināt vairāk un lasīt noveles letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'novele',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2189834',
          },
          {
            linkLabel: 'Rūdolfa Blaumaņa noveles',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2191075&r=150',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'LITERĀRĀ PASAKA',
      text: '<span style="font-weight: bold;">Literārā pasaka ir neliela apjoma prozas darbs, kam pamatā ir fantastisks raksturs.</span> Pasakā darbojas izdomātas būtnes, un cilvēkiem tiek piedēvētas pārdabiskas spējas. Atšķirībā no tautas pasakām, literārajai pasakai vienmēr ir zināms autors. Literārajās pasakās izteikti izmantota personifikācija – dzīvniekiem, priekšmetiem vai parādībām tiek piedēvētas cilvēkam raksturīgas īpašības un spējas.',
      isProseSubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk Nacionālajā enciklopēdijā:',
        readMoreLinks: [
          {
            linkLabel: '"Ziemas pasakas"',
            linkUrl:
              'https://enciklopedija.lv/skirklis/106852-%E2%80%9CZiemas-pasakas%E2%80%9D',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel: 'Lasīt vairāk letonika.lv Dzejas vārdnīcā:',
        readMoreLinks: [
          {
            linkLabel: 'literārā pasaka',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32752',
          },
        ],
      },
      readMoreThird: {
        readMoreLabel:
          'Uzzināt vairāk un lasīt literārās pasakas letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'pasaka',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2191144',
          },
          {
            linkLabel: 'Rūdolfs Blaumanis "Velniņi"',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2201602&r=170',
          },
          {
            linkLabel: 'Kārlis Skalbe "Kā es braucu Ziemeļmeitas lūkoties"',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=290',
          },
          {
            linkLabel: 'Anna Sakse "Pasakas par Ziediem"',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=363',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'MINIATŪRA',
      text: '<span style="font-weight: bold;">Miniatūra ir neliela apjoma tematiski un kompozicionāli pabeigts prozas vai dramatisks sacerējums ar pārdomu raksturu.</span> Miniatūrai piemīt lakonisks stils, formas virtuozitāte un saturisks dziļums. Tajā pašā laikā miniatūrā izteikti parādās <span style="font-weight: bold;">sajūtu gleznas</span> – radot noskaņu, paužot emocijas un asociācijas. ',
      isProseSubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk letonika.lv Dzejas vārdnīcā:',
        readMoreLinks: [
          {
            linkLabel: 'miniatūra',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32645',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel:
          'Uzzināt vairāk un lasīt miniatūras letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'miniatūra',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=3112657',
          },
          {
            linkLabel: 'Regīna Ezera “Zvaigžņu lietus”',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=279',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'EPIFĀNIJA',
      text: '<span style="font-weight: bold;">Epifānija ir neliela apjoma, emocionāli bagāts prozas darbs.</span> Epifānijas ir kā pēkšņa atklāsme vai atziņa par kādu parādību vai notikumu, uzplaiksnījumi, kur savijas esejisks apcerīgums, publicistika, ironija un filozofiskas atziņas. Sīkās, nenozīmīgās parādībās ir ietverts būtisks saturs, tiek meklētas fantastiskas kopsakarības.',
      isProseSubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk Nacionālajā enciklopēdijā:',
        readMoreLinks: [
          {
            linkLabel: 'Imants Ziedonis',
            linkUrl: 'Imants Ziedonis',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel: 'Lasīt vairāk letonika.lv Dzejas vārdnīcā:',
        readMoreLinks: [
          {
            linkLabel: 'epifānija',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32345',
          },
        ],
      },
      readMoreThird: {
        readMoreLabel:
          'Uzzināt vairāk un lasīt epifānijas letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'epifānija',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2190927',
          },
          {
            linkLabel: 'Imants Ziedonis “Epifānijas”',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=251',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'ESEJA',
      text: '<span style="font-weight: bold;">Eseja ir neliels, mākslinieciski kritisks sacerējums, kurā ir emocionāls piesātinājums, tieksme uz domu šifriem un simboliem.</span> Autora filozofiskās un teorētiskās pārdomas par kādu zinātnisku vai filozofisku literatūras vai mākslas problēmu. Esejā parādās autora subjektīvi iestarpinājumi, subjektīvā attieksme. Esejās tiek izteikts apgalvojums, tas tiek argumentēts un beigās tiek izdarīts secinājums. ',
      isProseSubtitle: true,
      readMore: {
        readMoreLabel: 'Uzzināt vairāk un lasīt esejas letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'eseja',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2198258',
          },
          {
            linkLabel: 'Zenta Mauriņa “Domu varavīksne”',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=383',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'LIROEPIKA',
      text: '<span style="font-weight: bold;">Liroepika ir daiļliteratūras starpveids starp liriku un epiku, kurā apvienotas dzejas un prozas (dažreiz arī dramaturģijas) iezīmes.</span> Tie ir sižetiski darbi, kas  rakstīti vārsmotā (saistītā valodā).',
      isEpicPoetry: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk letonika.lv Dzejas vārdnīcā:',
        readMoreLinks: [
          {
            linkLabel: 'liroepika',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32584',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel:
          'Uzzināt vairāk un lasīt 12 liroepikas darbus un darbu izlases letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'liroepika',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=3112613',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'POĒMA',
      text: '<span style="font-weight: bold;">Poēma ir dzejas darbs ar lirisku vai liroepisku sižetu par liktenīgu, cildenu cīņu atsevišķa cilvēka, kādas sociālās grupas vai tautas dzīvē.</span> Galvenā tematika – cīnītāju, karotāju dzīve, cīņas.',
      isEpicPoetrySubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk Nacionālajā enciklopēdijā:',
        readMoreLinks: [
          {
            linkLabel: '"Straumēni"',
            linkUrl:
              'https://enciklopedija.lv/skirklis/106218-%E2%80%9CStraum%C4%93ni%E2%80%9D',
          },
          {
            linkLabel: '"Daugava"',
            linkUrl:
              'https://enciklopedija.lv/skirklis/54476-%E2%80%9CDaugava%E2%80%9D',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel: 'Lasīt vairāk letonika.lv Dzejas vārdnīcā:',
        readMoreLinks: [
          {
            linkLabel: 'poēma',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32781',
          },
        ],
      },
      readMoreThird: {
        readMoreLabel: 'Uzzināt vairāk un lasīt poēmas letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'poēma',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2473224',
          },
          {
            linkLabel: 'Aleksandrs Čaks "Mūžības skartie"',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=259',
          },
          {
            linkLabel: 'Vilis Plūdons "Atraitnes dēls"',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=348',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'EPOSS',
      text: '<span style="font-weight: bold;">Eposs ir uz folkloras pamata radies literārs darbs par svarīgiem notikumiem tautas dzīvē.</span> Eposa centrālā persona ir kultūrvaronis, tiek cildināta varonība, kas izpaužas cīņā par tautas interesēm.  Eposam ir būtiska nozīme tautas kultūrapziņā un mītiskajos priekšstatos.',
      isEpicPoetrySubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk Nacionālajā enciklopēdijā:',
        readMoreLinks: [
          {
            linkLabel: '"Lāčplēsis", eposs',
            linkUrl:
              'https://enciklopedija.lv/skirklis/37565-%E2%80%9CL%C4%81%C4%8Dpl%C4%93sis%E2%80%9D,-eposs',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel: 'Lasīt vairāk letonika.lv Dzejas vārdnīcā:',
        readMoreLinks: [
          {
            linkLabel: 'eposs',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32361',
          },
          {
            linkLabel: 'varonis',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=33066',
          },
        ],
      },
      readMoreThird: {
        readMoreLabel: 'Uzzināt vairāk un lasīt poēmas letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'eposs',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2189832',
          },
          {
            linkLabel: 'Andrejs Pumpurs "Lāčplēsis"',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2194401&r=163',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'BALĀDE',
      text: '<span style="font-weight: bold;">Balādei raksturīgs varoņdarba, traģiska vai fantastiska notikuma attēlojums, spraiga darbība un dramatisks vēstījums.</span> Balādē noslēpumainais mijas ar reālo. Vēsturiski balāde ir deju dziesma, izpildīta dziedot un dejojot.',
      isEpicPoetrySubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk letonika.lv Dzejas vārdnīcā:',
        readMoreLinks: [
          {
            linkLabel: 'balāde',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32175',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel: 'Lasīt balādes letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'Vilis Plūdons “Salgales Mada loms”',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=346',
          },
          {
            linkLabel: 'Rūdolfs Blaumanis “Tālavas taurētājs”',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2196238&r=153',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'FABULA',
      text: '<span style="font-weight: bold;">Fabula ir apjoma alegorisks daiļdarbs dzejā vai prozā.</span> Fabulā galvenokārt darbojas personificēti un tipizēti dzīvnieki, kam piemīt cilvēciskas īpašības. Fabulas saturs ir ironisks vai satīrisks, tās beigās tiek izteikta kāda pamācība.',
      isEpicPoetrySubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk letonika.lv Dzejas vārdnīcā:',
        readMoreLinks: [
          {
            linkLabel: 'fabula',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32370',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel: 'Fabulu piemēri:',
        readMoreLinks: [
          {
            linkLabel: 'Pēteris Sils “Žagatas”, Ēzops “Lapsa un kaza”',
            linkUrl: '',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'DRĀMA jeb DRAMATURĢIJA',
      text: '<span style="font-weight: bold;">Drāma plašākā nozīmē  ir jebkurš dramaturģijas darbs  –  luga.</span> Tas ir literārs darbs, kura teksts sastāv no monologiem, dialogiem, polilogiem. Lugas tekstā parādās arī remarkas (autora piezīmes par darbības vietu, laiku, personu izskatu, vides iekārtojumu). Lugas primāri ir paredzētas iestudēšanai teātrī. Lugas var būt rakstītas gan saistītā (vārsmotā), gan nesaistītā valodā. Tradicionāli lugas uzbūvē ir ekspozīcija, sarežģījums, kāpinājums, kulminācija un atrisinājums.',
      isDramaturgy: true,
      readMore: {
        readMoreLabel: 'Uzzināt vairāk un lasīt 47 lugas letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'dramaturģija',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2189833',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'DRĀMA',
      text: '<span style="font-weight: bold;">Drāmā tiek attēloti konflikti cilvēku dzīvē.</span> Sižeti ir pietuvināti reālajai dzīvei, pievēršoties detalizētam raksturu atveidojumam. Drāmā parasti ir skumjš atrisinājums. Drāmas paveids ir  simboliskā drāma, kurā vēstījums atklājas caur simboliem.',
      isDramaturgySubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk Nacionālajā enciklopēdijā:',
        readMoreLinks: [
          {
            linkLabel: 'drāma',
            linkUrl: 'https://enciklopedija.lv/skirklis/4681-dr%C4%81ma',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel: 'Lasīt drāmas letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'Rūdolfs Blaumanis “Indrāni”',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=210',
          },
          {
            linkLabel: 'Rainis "Uguns un nakts"',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2196240&r=159',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'TRAĢĒDIJA',
      text: '<span style="font-weight: bold;">Traģēdijas pamatā ir galvenā varoņa nesamierināmi konflikti ar apkārtējo pasauli un bojāeja cīņā ar neuzvaramu pretspēku.</span> Spilgti paustas galveno varoņu ciešanas un pārdzīvojumi.',
      isDramaturgySubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk Nacionālajā enciklopēdijā:',
        readMoreLinks: [
          {
            linkLabel: 'traģēdija',
            linkUrl:
              'https://enciklopedija.lv/skirklis/3916-tra%C4%A3%C4%93dija',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel: 'Lasīt traģēdijas letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'Mārtiņš Zīverts “Vara”',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=289',
          },
          {
            linkLabel: 'Rainis "Jāzeps un viņa brāļi"',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2473306&r=196',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'KOMĒDIJA',
      text: '<span style="font-weight: bold;">Komēdijā tiek atainoti komiski tēli komiskās situācijās.</span> Komēdijā tiek attēlotas un apsmietas cilvēciskās vājības. Komisms var izpausties kā tēlotajās situācijās un cilvēku raksturos, tā arī verbāli – monologos, dialogos, polilogos. Komiskais bieži vien mijas ar skumjām par cilvēku ilūzijām un maldiem.',
      isDramaturgySubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk Nacionālajā enciklopēdijā:',
        readMoreLinks: [
          {
            linkLabel: 'komēdija',
            linkUrl: 'https://enciklopedija.lv/skirklis/3923-kom%C4%93dija',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel: 'Lasīt komēdijas letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'Rūdolfs Blaumanis “Skroderdienas Silmačos”',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2196240&r=164',
          },
          {
            linkLabel: 'Mārtiņš Zīverts “Minhauzena precības”',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=288',
          },
        ],
      },
    },
    {
      typeOrGenreTitle: 'PASAKU LUGA',
      text: '<span style="font-weight: bold;">Pasaku lugas pamatā autors izmanto tautas pasaku tēlus vai notikumus.</span> Pasaku luga sastāv no tādiem pašiem elementiem kā citas lugas (monologs, dialogs, remarkas u.c.), taču tai ir raksturīgi brīnumaini tēli un notikumi, kas īstajā dzīvē nebūtu iespējami, bieži vien arī simboli.',
      isDramaturgySubtitle: true,
      readMore: {
        readMoreLabel: 'Lasīt vairāk Nacionālajā enciklopēdijā:',
        readMoreLinks: [
          {
            linkLabel: '"Zelta zirgs"',
            linkUrl:
              'https://enciklopedija.lv/skirklis/96269-%E2%80%9CZelta-zirgs%E2%80%9D',
          },
        ],
      },
      readMoreSecond: {
        readMoreLabel: 'Lasīt pasaku lugas letonika.lv Lasītavā:',
        readMoreLinks: [
          {
            linkLabel: 'Rainis "Zelta zirgs"',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=211',
          },
          {
            linkLabel: 'Anna Brigadere “Sprīdītis”',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=225',
          },
        ],
      },
    },
  ];
  script: any;

  loadGraph() {
    // create an array with nodes
    var nodes = new vis.DataSet([
      //Top node
      {
        id: 1,
        font: { multi: 'html' },
        label: '<b>DAIĻLITERATŪRA</b>',
        color: '#D2E7E3',
      },
      //
      {
        id: 2,
        font: { multi: true },
        label: '<b>LIRIKA jeb DZEJA</b>',
        color: '#FFDADA',
      },
      {
        id: 3,
        font: { multi: true },
        label: '<b>EPIKA jeb PROZA</b>',
        color: '#ECE7B9',
      },
      {
        id: 4,
        font: { multi: true },
        label: '<b>LIROEPIKA</b>',
        color: '#BCE7FF',
      },
      {
        id: 5,
        font: { multi: true },
        label: '<b>DRĀMA jeb DRAMATURĢIJA</b>',
        color: '#D8CAF5',
      },
      //
      {
        id: 6,
        font: { multi: true },
        label: '<b>ROMĀNS</b>',
        color: '#F6F4DA',
      },
      {
        id: 7,
        font: { multi: true },
        label: '<b>STĀSTS</b>',
        color: '#F6F4DA',
      },
      {
        id: 8,
        font: { multi: true },
        label: '<b>NOVELE</b>',
        color: '#F6F4DA',
      },
      {
        id: 9,
        font: { multi: true },
        label: '<b>LITERĀRĀ PASAKA</b>',
        color: '#F6F4DA',
      },
      {
        id: 10,
        font: { multi: true },
        label: '<b>MINIATŪRA</b>',
        color: '#F6F4DA',
      },
      {
        id: 11,
        font: { multi: true },
        label: '<b>EPIFĀNIJA</b>',
        color: '#F6F4DA',
      },
      {
        id: 12,
        font: { multi: true },
        label: '<b>ESEJA</b>',
        color: '#F6F4DA',
      },
      //
      {
        id: 13,
        font: { multi: true },
        label: '<b>POĒMA</b>',
        color: '#DDF3FF',
      },
      {
        id: 14,
        font: { multi: true },
        label: '<b>EPOSS</b>',
        color: '#DDF3FF',
      },
      {
        id: 15,
        font: { multi: true },
        label: '<b>BALĀDE</b>',
        color: '#DDF3FF',
      },
      {
        id: 16,
        font: { multi: true },
        label: '<b>FABULA</b>',
        color: '#DDF3FF',
      },
      //
      {
        id: 17,
        font: { multi: true },
        label: '<b>DRĀMA</b>',
        color: '#F2EDFD',
      },
      {
        id: 18,
        font: { multi: true },
        label: '<b>TRAĢĒDIJA</b>',
        color: '#F2EDFD',
      },
      {
        id: 19,
        font: { multi: true },
        label: '<b>KOMĒDIJA</b>',
        color: '#F2EDFD',
      },
      {
        id: 20,
        font: { multi: true },
        label: '<b>PASAKU LUGA</b>',
        color: '#F2EDFD',
      },
    ]);

    // create an array with edges
    var edges = new vis.DataSet([
      //
      { from: 1, to: 2, color: { inherit: 'both' } },
      { from: 1, to: 3, color: { inherit: 'both' } },
      { from: 1, to: 4, color: { inherit: 'both' } },
      { from: 1, to: 5, color: { inherit: 'both' } },
      //
      { from: 3, to: 6, color: { inherit: 'both' } },
      { from: 3, to: 7, color: { inherit: 'both' } },
      { from: 3, to: 8, color: { inherit: 'both' } },
      { from: 3, to: 9, color: { inherit: 'both' } },
      { from: 3, to: 10, color: { inherit: 'both' } },
      { from: 3, to: 11, color: { inherit: 'both' } },
      { from: 3, to: 12, color: { inherit: 'both' } },
      //
      { from: 4, to: 13, color: { inherit: 'both' } },
      { from: 4, to: 14, color: { inherit: 'both' } },
      { from: 4, to: 15, color: { inherit: 'both' } },
      { from: 4, to: 16, color: { inherit: 'both' } },
      //
      { from: 5, to: 17, color: { inherit: 'both' } },
      { from: 5, to: 18, color: { inherit: 'both' } },
      { from: 5, to: 19, color: { inherit: 'both' } },
      { from: 5, to: 20, color: { inherit: 'both' } },
    ]);

    // create a network
    var container = document.getElementById('mynetwork');

    // provide the data in the vis format
    var data = {
      nodes: nodes,
      edges: edges,
    };
    var options = {
      edges: {
        arrows: {
          to: {
            enabled: true,
            imageHeight: undefined,
            imageWidth: undefined,
            scaleFactor: 1,
            src: undefined,
            type: 'arrow',
          },
        },

        font: {
          size: 12,
          bold: {
            color: '#0077aa',
          },
        },

        widthConstraint: {
          maximum: 3000,
        },
      },

      nodes: {
        shape: 'box',
        margin: 8,
        widthConstraint: {
          maximum: 160, //node width
        },
      },
      interaction: { dragNodes: true },
      physics: {
        enabled: true, // if true, nodespacing unavailable
        hierarchicalRepulsion: {
          nodeDistance: 180,
        },
      },

      edgesStyle: {
        width: 2,
        shadow: true,
      },

      layout: {
        //randomSeed: undefined,
        improvedLayout: true,
        clusterThreshold: 150,
        hierarchical: {
          enabled: false,
          levelSeparation: 150, // between levels
          nodeSpacing: 180,
          treeSpacing: 100,
          blockShifting: true,
          edgeMinimization: true,
          parentCentralization: true,
          direction: 'UD', // UD, DU, LR, RL
          sortMethod: 'hubsize', // hubsize, directed
          shakeTowards: 'leaves', // roots, leaves
        },
      },
    };

    // initialize your network!
    var network = new vis.Network(container, data, options);

    network.on('click', (params: any) => {
      let nodeValue = params.nodes[0];

      switch (nodeValue) {
        // daiļiteratūra
        case 1:
          (this.target as any)._results[0].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // lirika
        case 2:
          (this.target as any)._results[1].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // epika
        case 3:
          (this.target as any)._results[2].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // liroepika
        case 4:
          (this.target as any)._results[10].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // drāma jeb dramaturģija
        case 5:
          (this.target as any)._results[15].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // romāns
        case 6:
          (this.target as any)._results[3].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // stāsts
        case 7:
          (this.target as any)._results[4].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // novele
        case 8:
          (this.target as any)._results[5].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // literārā pasaka
        case 9:
          (this.target as any)._results[6].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // miniatūra
        case 10:
          (this.target as any)._results[7].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // epifānija
        case 11:
          (this.target as any)._results[8].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // eseja
        case 12:
          (this.target as any)._results[9].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // poēma
        case 13:
          (this.target as any)._results[11].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // eposs
        case 14:
          (this.target as any)._results[12].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // balāde
        case 15:
          (this.target as any)._results[13].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // fabula
        case 16:
          (this.target as any)._results[14].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // drāma
        case 17:
          (this.target as any)._results[16].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // traģēdija
        case 18:
          (this.target as any)._results[17].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // komēdija
        case 19:
          (this.target as any)._results[18].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // pasaku luga
        case 20:
          (this.target as any)._results[19].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
      }
    });
  }

  constructor(public scriptsService: ScriptService) {
    this.scriptsService
      .load('literaturasveidiunzanri')
      .then(() => {
        this.loadGraph();
      })
      .catch((error: any) => console.log(error));
  }

  ngOnInit(): void {}
}
