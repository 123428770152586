<div class="col-12">
  <div class="row section">
    <div class="row links">
      <div [innerHTML]="this.linksContent | safe: 'html'" class="letonika-link">
        {{ this.linksContent | safe: "html" }}
      </div>
    </div>
  </div>

  <div class="row"></div>
</div>
