<footer id="footer" [ngClass]="getFooterStyle()">
  <div class="container">
    <div class="row footer-row">
      <div class="col-md-8 footer-text-content">
        <div class="row">
          Saturs: Ieva Āne-Miķelsone, Gunta Kaula, Anete Bērziņa
        </div>
        <div class="row">Izstrāde un dizains: SIA Tilde</div>
        <div class="row">
          Projekts "Virtuālais asistents un digitālā platforma literatūras
          apguvei 7.-9. klases posmā" īstenots ar CFLA atbalstu
        </div>
      </div>
      <div class="col footer-img">
        <img id="footer-image" src="assets/images/footer-img.png" alt="" />
      </div>
    </div>
  </div>
</footer>
