import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScriptService } from 'src/app/scripts-store/script.service';
import { environment } from 'src/environments/environment';
import { Literature, PagesData } from '../../views/literature/literature';

/**
 * Declares the WebChat property on the window object.
 */
declare global {
  interface Window {
    WebChat: any;
  }
}

window.WebChat = window.WebChat || {};

let styleSet: any;

@Component({
  selector: 'app-literature-top',
  templateUrl: './literature-top.component.html',
  styleUrls: ['./literature-top.component.scss'],
  providers: [ScriptService],
})
export class LiteratureTopComponent implements OnInit {
  @ViewChild('botWindow', { static: false }) botWindowElement!: ElementRef;
  baseUrl = environment.baseUrl;
  @Input()
  literatureData!: Literature;

  @Input() pagesData!: PagesData;

  @Input()
  assistant!: boolean;
  @Input() type!: string;

  componentColor = 'blue';
  store: any;
  directline: any;
  activeRoutParameter = '';
  directlineSecrets = [
    {
      id: 'kauja-pie-knipskas',
      name: 'cibins',
      secret: '56_aDLPIzqg.sfPg3ke9lddNRsZgpzTXv8G7ZF6YWZy3i9q69uz3UD8',
    },
    {
      id: 'skroderdienas-silmacos',
      name: 'skroderiene',
      secret: 'ET9NLcf512s.HZhZ23GmArHTm0F2nYbH4lYdtxu2SNEhve-rOG3Au5s',
    },
    {
      id: 'naves-ena',
      name: 'zvejnieks',
      secret: 'QAvThSe7_Ik.XdDhkdvakDxmxdOmAIIj-9sIPdM3lfI14vkodGA04Po',
    },
    {
      id: 'uzmini-zanru',
      name: 'uzmini-zanru-vai-darbu',
      secret: '6lSv-uz--l0.VcJx-34Njr8UxN7L8DhOfGStcCP10flvwJ4keou-Goo',
    },
  ];
  constructor(
    private route: ActivatedRoute,
    private scriptsService: ScriptService
  ) {
    this.scriptsService
      .load('webchat')
      .then(() => {
        this.parseParameters();
      })
      .catch((error: any) => console.log(error));
  }

  ngOnInit(): void {}

  parseParameters() {
    if (this.assistant) {
      setTimeout(() => {
        if (this.route.snapshot.paramMap.get('id') == 'kauja-pie-knipskas') {
          this.initWebchat('kauja-pie-knipskas');
        } else if (
          this.route.snapshot.paramMap.get('id') == 'uzmini-zanru-vai-darbu'
        ) {
          this.initWebchat('uzmini-zanru');
        } else if (this.route.snapshot.paramMap.get('id') == 'naves-ena') {
          this.initWebchat('naves-ena');
        } else {
          this.initWebchat('skroderdienas-silmacos');
        }
      }, 500);
      this.componentColor = 'green';
    }
  }

  changeChatAnimation(): any {
    const url = this.route.snapshot.paramMap.get('id');

    if (url === 'uzmini-zanru-vai-darbu') {
      return 'assets/images/Cibinsh_Speele_VA_lapa_1.svg';
    } else if (url === 'kauja-pie-knipskas') {
      return 'assets/images/Cibinsh_VA_lapa_1a.svg';
    } else if (url === 'naves-ena') {
      return 'assets/images/Zvejnieks_VA _lapa_2.svg';
    } else if (url === 'skroderdienas-silmacos') {
      return 'assets/images/Skroderiene_VA_lapai_4.svg';
    }
  }

  initWebchat(secret?: string) {
    const literatureName = this.route.snapshot.paramMap.get('id');
    this.activeRoutParameter = literatureName ? literatureName : '';
    let avatarUrl = '';
    // Set avatar by url
    switch (literatureName) {
      case 'naves-ena':
        avatarUrl = 'assets/images/Zvejnieks-ikona-cats.png';
        break;
      case 'uzmini-zanru-vai-darbu':
        avatarUrl = 'assets/images/Cibiņš-ikona-cats.png';
        break;
      case 'kauja-pie-knipskas':
        avatarUrl = 'assets/images/Cibiņš-ikona-cats.png';
        break;
      case 'skroderdienas-silmacos':
        avatarUrl = 'assets/images/Skroderdienas-ikona-cats.png';
        break;
      default:
        break;
    }
    styleSet = window.WebChat.createStyleSet({
      botAvatarInitials: 'VA',
      backgroundColor: 'transparent',
      sendBoxBackground: 'transparent',
      bubbleBackground: 'white',
      bubbleTextColor: '#4C5260',
      markdownRespectCRLF: true,
      bubbleBorderWidth: 2,
      bubbleFromUserBorderWidth: 2,
      bubbleFromUserBorderColor: '#ACACAC',
      bubbleBorderColor: '#1F8D85',
      bubbleFromUserBackground: 'white',
      bubbleFromUserTextColor: '#4C5260',
      sendBoxBorderTop: '2px solid #ACACAC',
      sendBoxBorderLeft: '2px solid #ACACAC',
      sendBoxBorderBottom: '2px solid #ACACAC',
      sendBoxBorderRight: '2px solid #ACACAC',
      subtle: 'darkgray',
      sendBoxButtonColorOnFocus: '#1F8D85',
      sendBoxButtonColorOnHover: '#1F8D85',
      sendBoxTextColor: 'black',
      paddingRegular: '5px!important',
      paddingWide: '10px!important',
      sendBoxHeight: 50,
      hideUploadButton: true,
      typingAnimationBackgroundImage: "url('/assets/images/typing.gif')",
      typingAnimationWidth: 180,
      bubbleMinHeight: 30,
      suggestedActionBackground: 'transparent',
      suggestedActionBorder: undefined, // split into 3, null
      suggestedActionBorderColor: 'transparent', // defaults to accent
      suggestedActionBorderStyle: 'solid',
      suggestedActionBorderWidth: 1,
      suggestedActionBorderRadius: 2,
      suggestedActionImageHeight: 20,
      suggestedActionTextColor: '#1f8d85',
      suggestedActionDisabledBackground: undefined, // defaults to suggestedActionBackground
      suggestedActionHeight: 40,
      bubbleMaxWidth: 290,
      groupTimestamp: 0,
      botAvatarImage: avatarUrl,
    });

    let botSecret;
    if (secret) {
      botSecret = this.directlineSecrets.find(
        (dlSecret) => dlSecret.id === secret || dlSecret.name === secret
      );
    }
    this.store = window.WebChat.createStore(
      {},
      // @ts-ignore: Unreachable code error
      ({ dispatch }) =>
        // @ts-ignore: Unreachable code error
        (next) =>
        // @ts-ignore: Unreachable code error
        (action) => {
          if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
            dispatch({
              type: 'WEB_CHAT/SEND_EVENT',
              payload: {
                name: 'webchat/join',
                value: {
                  locale: 'lv-lv',
                  host: location.protocol + '//' + location.hostname,
                },
              },
            });
          }

          return next(action);
        }
    );

    this.directline = window.WebChat.createDirectLine({
      secret: botSecret
        ? botSecret.secret
        : '56_aDLPIzqg.sfPg3ke9lddNRsZgpzTXv8G7ZF6YWZy3i9q69uz3UD8',
      webSocket: true,
    });

    // @ts-ignore: Unreachable code error
    let avatarMiddleware =
      () =>
      // @ts-ignore: Unreachable code error
      (next) =>
      // @ts-ignore: Unreachable code error
      ({ fromUser, ...otherArgs }) => {
        if (
          otherArgs.activity.channelData &&
          otherArgs.activity.channelData.avatar
        ) {
          return (
            '<img  style="border-radius: 50px;" src="' +
            otherArgs.activity.channelData.avatar +
            '"/>'
          );
        }
        return next({ fromUser, ...otherArgs });
      };

    window.WebChat.renderWebChat(
      {
        directLine: this.directline,
        locale: localStorage.getItem('language') || 'lv',
        styleSet,
        store: this.store,
        avatarMiddleware,
      },
      this.botWindowElement.nativeElement
    );
  }
  ngOnChanges() {
    if (
      this.activeRoutParameter &&
      this.activeRoutParameter !== this.route.snapshot.paramMap.get('id')
    ) {
      setTimeout(() => {
        this.initWebchat(this.route.snapshot.paramMap.get('id') || '');
      }, 1000);
    }
  }
}
