<ng-container *ngIf="literatureData || pagesData">
  <app-literature-top
    [literatureData]="literatureData"
    [pagesData]="pagesData"
  ></app-literature-top>
  <div class="background">
    <ng-container *ngFor="let element of literatureBlock">
      <app-literature-block
        [title]="element.title"
        [show]="element.show"
        [content]="element.content"
        [type]="element.type"
      ></app-literature-block>
    </ng-container>
  </div>
</ng-container>
