import { Component, OnInit } from '@angular/core';
import { ToolsForCreativity } from '../../views/knowledge/knowledge';

@Component({
  selector: 'app-tools-for-creativity-block',
  templateUrl: './tools-for-creativity-block.component.html',
  styleUrls: ['./tools-for-creativity-block.component.scss'],
})
export class ToolsForCreativityBlockComponent implements OnInit {
  toolsForCreativityBlock: ToolsForCreativity[] = [
    {
      title: 'Vārdu mākonis',
      description:
        'Internetā brīvi pieejams rīks vietnē <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://www.wordclouds.com/" target="_blank">Wordclouds</a>. Vārdu mākonim var izvēlēties vārdus un to svaru, formas, krāsas, fontu. Gatavo darbu iespējams saglabāt kā zīmējumu.',
      exampleLink: [
        {
          linkLabel: 'vārdu mākonis poēmai “Atraitnes dēls”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Wordclouds',
        },
      ],
      color: '#CCCEE2',
    },
    {
      title: 'Spriedzes diagramma',
      description:
        'Internetā brīvi pieejams diagrammu ģenerēšanas rīks vietnē <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://www.rapidtables.com/tools/line-graph.html" target="_blank">Rapidtables</a>. Daiļdarba notikumus raksturojot ar skaitlisku vērtību, var radīt spriedzes līkni (“kardiogrammu”).',
      exampleLink: [
        {
          linkLabel: 'spriedzes līkne stāstam “Kauja pie Knipskas”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Spriedze',
        },
      ],
      color: '#D7D9CD',
    },
    {
      title: 'Emociju diagramma',
      description:
        'Internetā brīvi pieejams diagrammu ģenerēšanas rīks vietnē <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://www.rapidtables.com/tools/line-graph.html" target="_blank">Rapidtables</a>. Iespēja vizuāli attēlot emociju koipumu, lasot daiļdarbu vai pēc tā izlasīšanas.',
      exampleLink: [
        {
          linkLabel: 'emociju diagramma stāstam “Kauja pie Knipskas”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Emocijas',
        },
      ],

      color: '#C6D2F7',
    },
    {
      title: 'Venna diagramma',
      description:
        'Internetā brīvi pieejams <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://www.canva.com/graphs/venn-diagrams/" target="_blank">Venna diagrammas ģenerēšanas rīks vietnē Canva</a>. Var radīt vizuālu attēlojumu kopīgajam un atšķirīgajam starp lasītāju un daiļdarba varoni.',
      exampleLink: [
        {
          linkLabel: 'Venna diagramma stāstam “Kauja pie Knipskas”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#VennaD',
        },
      ],
      color: '#E3F1F9',
    },
    {
      title: 'Virtuālā tāfele',
      description:
        'Izmantojot digitālo rīku <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://padlet.com/" target="_blank">Padlet</a>, var saņemt atgriezenisko saiti par skatītām teātra izrādēm, lasītiem daiļdarbiem, veiktiem uzdevumiem. Virtuāla tāfele, pie kuras skolēni var piestiprināt savas līmlapiņas.',
      exampleLink: [
        {
          linkLabel: 'skatīt izrādes recenzijas piemēru',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#tafele',
        },
      ],
      color: '#F0D2D2',
    },
    {
      title: 'Domu kartes',
      description:
        'Vietne <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://padlet.com/" target="_blank">Padlet</a> piedāvā iespēju strukturēt informāciju, veidojot domu kartes individuāli vai grupās. Iespējams papildināt ar attēliem, saitēm uz ārējiem resursiem. Izveidoto domu karti var lejuplādēt.',
      exampleLink: [
        {
          linkLabel:
            'tēlainās izteiksmes līdzekļi esejā “Dziedinātājs klusums”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Padlet1',
        },
      ],
      color: '#E7E7E7',
    },
    {
      title: 'Interaktīva karte',
      description:
        'Vietne <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://padlet.com/" target="_blank">Padlet</a> piedāvā iespēju atspoguļot informāciju interaktīvā kartē, pievienojot dažādas ģeogrāfiskas vietas, to aprakstus, papildināt ar attēliem, saitēm uz ārējiem resursiem. Izveidoto karti var lejuplādēt.',
      exampleLink: [
        {
          linkLabel: 'Ojāra Vācieša vietas Latvijā',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Padlet3',
        },
      ],
      color: '#E2D5E4',
    },
    {
      title: 'Laika līnija',
      description:
        'Vietne <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://padlet.com/" target="_blank">Padlet</a> piedāvā iespēju atspoguļot informāciju interaktīvā laika līnijā, pievienojot informāciju par konkrētiem laika posmiem, gadiem Izveidoto laika līniju var lejuplādēt.',
      exampleLink: [
        {
          linkLabel: 'notikumi Ojāra Vācieša dzīvē',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Padlet2',
        },
      ],
      color: '#ECE7B9',
    },
    {
      title: 'Interaktīvu darba lapu veidošana',
      description:
        'Vietne <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://learningapps.org/" target="_blank">Learningapps</a> piedāvā attēlu, video un audio datņu iekļaušanu darba lapā un nodrošina iespēju izveidot daudzveidīgus uzdevumus. Vietnē pieejamas pamācības, kā veidot uzdevumus.',
      exampleLink: [
        {
          linkLabel: 'interaktīva darba lapa lugai “Skroderdienas Silmačos”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Learning',
        },
      ],
      color: '#EAFBD0',
    },
    {
      title: 'Interaktīvu darba lapu veidošana',
      description:
        'Vietne <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://app.wizer.me/" target="_blank">Wizer</a> nodrošina iespēju izveidot daudzveidīgus uzdevumus, iekļaujot attēlus, video un audio datnes. Iespēja veidot individualizētus uzdevumus. Pamācības darbam ar vietni <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://www.youtube.com/watch?v=q1zC6SPCnI4" target="_blank">te</a> un <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://www.youtube.com/watch?v=VCRILjVjNqI" target="_blank">te</a>. ',
      exampleLink: [
        {
          linkLabel: 'interaktīva darba lapa lugai “Skroderdienas Silmačos”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Wizer',
        },
      ],
      color: '#EAFBD0',
    },
    {
      title: 'Interaktīvu darba lapu veidošana',
      description:
        'Vietne <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://app.wizer.me/" target="_blank">Liveworksheets</a> nodrošina interaktīvu darba lapu izveidošanu pašvadītas mācīšanās procesam un darbam klasē. Vietnē ir pieejamas pamācības, kā veidot uzdevumus.',
      exampleLink: [
        {
          linkLabel: 'interaktīva darba lapa lugai “Skroderdienas Silmačos”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#LiveWork',
        },
      ],
      color: '#EAFBD0',
    },
    {
      title: 'Informācijas strukturēšana',
      description:
        'Vietne <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://coggle.it/" target="_blank">Coggle</a> piedāvā iespēju strukturēt informāciju, veidojot domu kartes individuāli vai grupās. Domu karti ir iespējams papildināt ar attēliem, saitēm uz ārējiem resursiem. Izveidoto darbu var lejuplādēt.',
      exampleLink: [
        {
          linkLabel:
            'tēlainās izteiksmes līdzekļi esejā “Dziedinātājs klusums”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Coggle',
        },
      ],
      color: '#DDCBDB',
    },
    {
      title: 'Krustvārdu mīkla',
      description:
        'Brīvi pieejams krustvārdu mīklu ģenerēšanas rīks vietnē <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://crosswordlabs.com/view/novele-naves-ena" target="_blank">Crosswordlabs</a>.  Atbalsta latviešu valodu, izveidotās mīklas var saglabāt. izmantojams gan kā satura pārbaudes uzdevumus, gan darbam ar tekstu.',
      exampleLink: [
        {
          linkLabel: 'krustvārdu mīkla tiešsaistē novelei “Nāves ēnā”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Crosw',
        },
      ],
      secondExampleLink: [
        {
          linkLabel: 'krustvārdu mīkla tiešsaistē stāstam “Sāpju mērs”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Crosw',
        },
      ],
      thirdExampleLink: [
        {
          linkLabel: 'krustvārdu mīkla tiešsaistē stāstam “Gripa”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Crosw',
        },
      ],
      color: '#E6E9FA',
    },
    {
      title: 'Burtu-skaitļu mīkla',
      description:
        'Šādu burtu mīklu var izveidot, katram alfabēta burtam piemērojot konkrētu ciparu. Mīklas saturu var veidot, pielāgojot sasniedzamajam rezultātam – darbam ar tekstu vai zināšanu pārbaudei.',
      exampleLink: [
        {
          linkLabel: 'burtu-skaitļu mīkla epifānijai “Apiet apkārt”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#BurtuM',
        },
      ],
      secondExampleLink: [
        {
          linkLabel: 'burtu-skaitļu mīkla epifānijai “Pudeles un kārbiņas”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#BurtuM',
        },
      ],
      thirdExampleLink: [
        {
          linkLabel: 'burtu-skaitļu mīkla epifānijai “Ir viens vārds”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#BurtuM',
        },
      ],
      color: '#CDD3D9',
    },
    {
      title: 'Infografika',
      description:
        'Izmantojot digitālo rīku <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://www.canva.com/create/infographics/" target="_blank">Canva-Infografics</a>, var veikt radošu uzdevumu – izveidot infografiku par brīvi izvēlētu daiļdarbu, darba varoni, tēmu. Gatavos darbus var lejuplādēt.',
      exampleLink: [
        {
          linkLabel: 'infografika “Lāčplēša izaugsme eposā”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Infografiks',
        },
      ],
      color: '#B6F6EA',
    },
    {
      title: 'Grafiti',
      description:
        'Izmantojot digitālo rīku <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://placeit.net/c/logos/stages/urban-style-logo-maker-with-graffiti-fonts-2803?gclid=CjwKCAjwn6GGBhADEiwAruUcKgMj7WgWuB27_J2MxTdetZPqqEDQZcZYJcS9RD1DDadfvfieQIE9uxoCI7QQAvD_BwE" target="_blank">Placeit-Graffiti</a>, var izveidot radošu darbu – savu grafiti par jebkuru daiļdarbu. Grafiti tekstam derēs īss citāts no daiļdarba, gatavo darbu iespējams saglabāt kā attēlu.',
      exampleLink: [
        {
          linkLabel: 'grafiti “Atraitnes dēls”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Grafiti',
        },
      ],
      color: '#CBDDDA',
    },
    {
      title: 'Abstraktā māksla',
      description:
        'Izmantojot abstraktās mākslas ģenerēšanas rīku <a style="color:#4C5260; text-decoration: none; font-weight: bold;" href="https://bomomo.com/" target="_blank">Bomomo</a>, radi literārā darba citāta vizuālu attēlojumu – abstraktu mākslas darbu!',
      exampleLink: [
        {
          linkLabel: 'abstraktā māksla “Kā es braucu Ziemeļmeitas lūkoties”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=rpashizpausme#Bomomo',
        },
      ],
      color: '#CBDDDA',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
