<div class="row virtual-assistant-banner">
  <div class="col-md-2">
    <h1>Virtuālie asistenti</h1>
  </div>
  <div class="col-md-2">
    <object
      type="image/svg+xml"
      data="../../../../assets/images/Zvejnieks_VA _lapa_2.svg"
      class="skroderiene-image"
    ></object>
    <p class="zvejnieks">es esmu Zvejnieks</p>
  </div>
  <div class="col-md-2 cibins-padding">
    <object
      type="image/svg+xml"
      data="../../../../assets/images/Cibinsh_VA_lapa_1a.svg"
      class="skroderiene-image"
    ></object>
    <p class="cibins">es esmu Cibiņš</p>
  </div>
  <div class="col-md-2">
    <object
      type="image/svg+xml"
      data="../../../../assets/images/Skroderiene_VA_lapai_4.svg"
      class="skroderiene-image"
    ></object>
    <p class="skroderiene">es esmu Skroderiene</p>
  </div>
  <div class="col-md-3 cibins-section">
    <object
      type="image/svg+xml"
      data="../../../../assets/images/Cibinsh_Speele_VA_lapa_1.svg"
      class="cibins-image"
    ></object>
  </div>
</div>
<div class="div virtual-assistant-info-section">
  <div class="row about-virtual-assistants">
    <h5>Kas ir virtuālie asistenti</h5>
    <p>
      Virtuālais asistents ir datorprogramma, kas uztur saziņu starp cilvēku un
      datoru, izpilda cilvēka rīkojumus, veic datu iegūšanu un attēlošanu.
      Virtuālos asistentus citos vārdos mēdz dēvēt arī par sarunbotiem vai
      tērzēšanas botiem.
    </p>
    <p>
      Virtuālos asistentus izmanto daudzās jomās, un tie veic dažādas
      sarežģītības uzdevumus, sākot no vienkāršas tērzēšanas līdz pat svarīgu
      datu izgūšanai no sarežģītām sistēmām. Sarunbots ir ne tikai vērtīgs rīks
      zināšanu apguvē, bet arī īpaši labs palīgs lietotāju atbalstā un klientu
      apkalpošanā, samazinot darbinieku noslodzi un neliekot atbildēt uz
      vienveidīgiem jautājumiem. Virtuālais asistents atbild ātrāk par cilvēku,
      turklāt vienlaikus var norisināties sarunas ar daudziem runātājiem jebkurā
      diennakts laikā.
    </p>
  </div>
  <div class="row try-virtual-assistants">
    <div class="row try-text">
      <h5>Izmēģini!</h5>
      <p>
        Lai pārliecinātos, cik virtuālie asistenti var būt dažādi un cik labi
        apguvuši mācību vielu,
        <br />
        aprunājies ar kādu no šiem asistentiem!
      </p>
    </div>
    <div class="talk-with-assistants">
      <div class="row">
        <div class="col-md-2 align-items">
          <img src="assets/images/Zvejnieks-ikona-green-bg.png" alt="" />
          <h5>Nāves ēnā</h5>
        </div>
        <div class="col-md-4 text">
          <span>Zvejnieks </span> trīs dažādos testos pārbauda zināšanas par
          Rūdolfa Blaumaņa noveli “Nāves ēnā”:
          <br />
          <br />
          <ul>
            <li>noveles tēli;</li>
            <li>noveles saturs;</li>
            <li>dabas un sajūtu apraksti.</li>
          </ul>
        </div>
        <div class="col-md-2 align-items">
          <img src="assets/images/Cibiņš-ikona-green-bg.png" alt="" />
          <h5>
            Kauja pie <br />
            Knipskas
          </h5>
        </div>
        <div class="col-md-4 text">
          <span> Cibiņš</span> pārbauda sarunbiedra zināšanas par Jāņa Poruka
          stāstu “Kauja pie Knipskas”, <br />
          labi pārzina savu laikmetu un audzināšanas metodes, par to pastāstot,
          <br />
          un prot atbildēt uz dažādiem mūsdienu skolēnu jautājumiem.
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-2 align-items">
          <img src="assets/images/Skroderdienas-ikona-green-bg.png" alt="" />
          <h5>
            Skroderdienas <br />
            Silmačos
          </h5>
        </div>
        <div class="col-md-4 text">
          <span> Skroderiene</span> pārbauda, cik labi sarunbiedram zināma
          Rūdolfa Blaumaņa drāma “Skroderdienas Silmačos”, trīs testos:
          <br />
          <br />
          <ul>
            <li>tēli;</li>
            <li>notikumi;</li>
            <li>valoda.</li>
          </ul>
        </div>
        <div class="col-md-2 align-items">
          <img src="assets/images/Cibiņš-ikona-green-bg.png" alt="" />
          <h5>
            Literatūras <br />
            minēšanas spēle
          </h5>
        </div>
        <div class="col-md-4 text">
          <span> Cibiņš</span> piedāvā iesaistīties daiļliteratūras žanru un
          darbu minēšanas spēlē. <br />
          Sarunbiedram jāiedomājas kāds daiļdarbs vai žanrs, un Cibiņš mēģina to
          uzminēt.
        </div>
      </div>
    </div>
  </div>
  <div class="row how-virtual-assistants-are-created">
    <p [innerHTML]="this.firstContent | safe: 'html'">
      {{ this.firstContent | safe: "html" }}
    </p>
  </div>
  <div class="row try-in-school">
    <p [innerHTML]="this.secondContent | safe: 'html'">
      {{ this.secondContent | safe: "html" }}
    </p>
  </div>
</div>
