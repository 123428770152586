import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/blocks/header/header.component';
import { FooterComponent } from './layouts/blocks/footer/footer.component';
import { TopBannerComponent } from './layouts/blocks/top-banner/top-banner.component';
import { BaseComponent } from './layouts/views/base/base.component';
import { LiteratureComponent } from './layouts/views/literature/literature.component';
import { KnowledgeComponent } from './layouts/views/knowledge/knowledge.component';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './layouts/views/home/home.component';
import { LiteratureBlockComponent } from './layouts/blocks/literature-block/literature-block.component';
import { SafePipe } from './pipes/safe.pipe';
import { VirtualAssistantsComponent } from './layouts/views/virtual-assistants/virtual-assistants.component';
import { AssistantChatComponent } from './layouts/views/virtual-assistants/assistant-chat/assistant-chat.component';
import { LiteratureTopComponent } from './layouts/blocks/literature-top/literature-top.component';
import { ArtisticExpressionBlockComponent } from './layouts/blocks/artistic-expression-block/artistic-expression-block.component';
import { LiteratureTypesGenreBlockComponent } from './layouts/blocks/literature-types-genre-block/literature-types-genre-block.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolsForCreativityBlockComponent } from './layouts/blocks/tools-for-creativity-block/tools-for-creativity-block.component';
import { WorksheetsBlockComponent } from './layouts/blocks/worksheets-block/worksheets-block.component';
import { TimeMapComponent } from './layouts/blocks/time-map/time-map.component';
import { MethodologyComponent } from './layouts/views/methodology/methodology.component';
import { HelperBlockComponent } from './layouts/blocks/helper-block/helper-block.component';
import { AboutProjectBlockComponent } from './layouts/blocks/about-project-block/about-project-block.component';
import { MethodologyBlockComponent } from './layouts/blocks/methodology-block/methodology-block.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    TopBannerComponent,
    BaseComponent,
    LiteratureComponent,
    KnowledgeComponent,
    HomeComponent,
    LiteratureBlockComponent,
    SafePipe,
    VirtualAssistantsComponent,
    AssistantChatComponent,
    LiteratureTopComponent,
    ArtisticExpressionBlockComponent,
    LiteratureTypesGenreBlockComponent,
    ToolsForCreativityBlockComponent,
    WorksheetsBlockComponent,
    TimeMapComponent,
    MethodologyComponent,

    HelperBlockComponent,
    AboutProjectBlockComponent,
    MethodologyBlockComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
