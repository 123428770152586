import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperBlockContent } from '../../views/methodology/methodology';

@Injectable({
  providedIn: 'root',
})
export class HelperBlockService {
  private url =
    'https://dmml-strapi-dev.azurewebsites.net/pages/pedagogiem-paligs-linki';

  constructor(private http: HttpClient) {}

  getContentForHelperPage() {
    return this.http.get<HelperBlockContent>(this.url);
  }
}
