import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ScriptService } from 'src/app/scripts-store/script.service';
import { TimeMapInfoBlock } from '../../views/knowledge/knowledge';

declare var vis: any;

@Component({
  selector: 'app-time-map',
  templateUrl: './time-map.component.html',
  styleUrls: ['./time-map.component.scss'],
  providers: [ScriptService],
})
export class TimeMapComponent implements OnInit {
  @ViewChild('visualization') networkContainer!: ElementRef;
  @ViewChildren('target')
  target!: ElementRef;

  @ViewChild('zoomOut')
  zoomOutEl!: ElementRef;
  @ViewChild('zoomIn')
  zoomInEl!: ElementRef;

  @ViewChild('moveLeft')
  moveLeftEl!: ElementRef;

  @ViewChild('moveRight')
  moveRightEl!: ElementRef;

  private visualization: any;

  timeMapInfoBlock: TimeMapInfoBlock[] = [
    {
      information: {
        text: '<span style="font-weight: bold;">Laika karte ar literatūras virzieniem, </span> atsevišķām Latvijas kultūras kanona vērtībām un nozīmīgākajiem vēstures notikumiem no 17. gadsimta vidus līdz 21. gadsimta sākumam. <br /> <br /> <span style="font-weight: bold;"> Lai pārvietotos pa karti un pietuvinātu/attālinātu laika asi, jānoklikšķina uz tās un jāvelk ar peli vai jāritina. </span> <br /> <span style="font-weight: bold;"> Lai iegūtu vairāk informācijas par kartes objektu, jānoklikšķina uz tā. </span> <br /> <br /> ',
        readMoreLabel: 'Lasīt vairāk par Latvijas kultūras kanonu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv apraksti',
            linkUrl: 'https://letonika.lv/groups/default.aspx?g=1&r=264',
          },
          {
            linkLabel: 'letonika.lv karte',
            linkUrl: 'https://letonika.lv/kolekcija/?id=kanons',
          },
          {
            linkLabel: 'Latvijas kultūras kanona mājaslapa',
            linkUrl: 'https://kulturaskanons.lv/',
          },
        ],
      },
      secondInformation: {
        text: '',
        readMoreLabel: 'Uzzināt vairāk par Latvijas vēstures notikumiem:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Vēstures enciklopēdija',
            linkUrl: 'https://letonika.lv/groups/default.aspx?g=1&r=2',
          },
          {
            linkLabel: 'letonika.lv Laika karte',
            linkUrl: 'https://letonika.lv/article.aspx?id=laikakarte',
          },
        ],
      },
      thirdInformation: {
        text: 'Literatūrzinātnes pētniecībā ir nošķirami divi līmeņi: teksts un tā veidošanas principi un teksts tā tapšanas kontekstā. Pirmais līmenis pievēršas poētikas un estētikas izpētei, analizējot teksta kompozīciju, tēlu sistēmu un mākslinieciskās izteiksmes līdzekļus. Otrais līmenis pievēršas daiļrades tipam, literārajam virzienam un stilam, kā arī autora biogrāfijai, sociālajam un kultūras kontekstam. <br /> <br />',
        readMoreLabel: 'Uzzināt vairāk par literatūrzinātni: ',
        readMoreLinks: [
          {
            linkLabel: 'Nacionālajā enciklopēdijā',
            linkUrl:
              'https://enciklopedija.lv/skirklis/3544-literat%C5%ABrzin%C4%81tne',
          },
        ],
      },
      fourthInformation: {
        text: 'Literārais virziens ir kāda laika posma literāros darbus un to rakstītājus apvienojoša kopība, kas izpaužas līdzīgā pasaules vai literatūras uztverē un radniecīgā izteiksmes veidā. Virziens ir jēdziens, ko lieto parasti attiecībā uz jaunlaiku literatūru, taču dažreiz runā arī par virzieniem antīkajā (īpaši romiešu) literatūrā.',
        readMoreLabel: 'Lasīt vairāk par virzieniem:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=33089',
          },
        ],
      },
    },
    {
      title:
        '<div style="height: 28px; background: #F4EFDC; font-family: RobotoBold; font-size: 20px; padding-left:20px;";>KLASICISMS</div>',
      information: {
        text: '<span style="font-weight: bold;">Klasicismam</span> (17.gs. vidus līdz 18.gs. beigas) ir raksturīga idealizēta dzīves īstenība, tēloti viengabalaini, cildeni varoņi bez spilgtām individuālām iezīmēm un emocijām, jo tās rada haosu. Obligāta darbības, laika un vietas vienība. ',
        readMoreLabel: 'Lasīt vairāk par klasicismu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=4&q=klasicisms&id=967695&g=1',
          },
          {
            linkLabel: 'letonika.lv enciklopēdijā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=1&q=klasicisms&id=944880&g=1',
          },
        ],
      },
      secondInformation: {
        text: 'Klasicisma darbs: Kristofers Fīrekers "Dievs Kungs ir mūsu stiprā pils" (tulkojums) un citas baznīcas dziesmas',
        readMoreLabel: 'Lasīt vairāk par darbu un autoru:',
        readMoreLinks: [
          {
            linkLabel: 'Nacionālajā enciklopēdijā',
            linkUrl:
              'https://enciklopedija.lv/skirklis/29014-Kristofors-F%C4%Abrekers',
          },
        ],
      },
    },
    {
      title:
        '<div style="height: 28px; background: #FFFFC8; font-family: RobotoBold; font-size: 20px; padding-left:20px;";>APGAISMES REĀLISMS</div>',
      information: {
        text: '<span style="font-weight: bold;">Apgaismes reālismam</span> (18.gs. otrā puse līdz 19.gs. vidus) ir raksturīga ticība cilvēces progresam, cilvēka prātam un brīvībai, aicinājums atgriezties pie dabas. Tēli atveidoti izteikti pozitīvi vai izteikti negatīvi.  Dzīve atveidota ar visām tās pretrunām.',
        readMoreLabel: 'Lasīt vairāk par apgaismes (apgaismības) reālismu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32693&r=4',
          },
          {
            linkLabel: 'letonika.lv enciklopēdijā',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=5856&r=1',
          },
        ],
      },
      secondInformation: {
        text: 'Apgaismes reālisma darbs: Garlībs Merķelis "Latvieši, sevišķi Vidzemē filozofiskā gadsimta beigās"',
        readMoreLabel: 'Lasīt darbu',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=162',
          },
        ],
      },
    },
    {
      title:
        '<div style="height: 28px; background: #DDF2DD; font-family: RobotoBold; font-size: 20px; padding-left:20px;";>SENTIMENTĀLISMS</div>',
      information: {
        text: '<span style="font-weight: bold;">Sentimentālismam</span> (18.gs. beigas līdz 20.gs. sākums) pretēji klasicismam raksturīga jūtu valoda, elēģiska, melanholiska noskaņa. Tēlota brīva, dabiska, atraisīta personība. Pārsvarā atveidota lauku dzīve.',
        readMoreLabel: 'Lasīt vairāk par sentimentālismu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?cid=32908&r=4&lid=32908',
          },
          {
            linkLabel: 'letonika.lv enciklopēdijā',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=24703',
          },
        ],
      },
      secondInformation: {
        text: 'Sentimentālisma darbs: Ķikuļa Jēkabs "Viena Vidzemes cietumnieku bēdu dziesma"',
        readMoreLabel: 'Lasīt vairāk par darbu un autoru:',
        readMoreLinks: [
          {
            linkLabel: 'LSM vietnē',
            linkUrl:
              'https://lr1.lsm.lv/lv/raksts/septini-gadsimti-lidz-valstij/kikulu-jekabs--hernhutietis-un-pirmais-latviesu-laicigais-dzejni.a87615/',
          },
        ],
      },
    },
    {
      title:
        '<div style="height: 28px; background: #DBF2F4; font-family: RobotoBold; font-size: 20px; padding-left:20px;";>ROMANTISMS</div>',
      information: {
        text: '<span style="font-weight: bold;">Romantismam</span> (19.gs. vidus līdz 20.gs. sākums) ir raksturīgas spēcīgas ilgas pēc personības radošas brīvības, nesamierināšanās ar pastāvošo kārtību un vēlme to mainīt. Izmantoti pretstati, simboliski tēli.',
        readMoreLabel: 'Lasīt vairāk par romantismu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32864',
          },
          {
            linkLabel: 'letonika.lv enciklopēdijā',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=23617',
          },
          {
            linkLabel: 'valoda.ailab.lv vietnē',
            linkUrl:
              'http://valoda.ailab.lv/kultura/vesture/kultura/kult19/teksts.htm',
          },
        ],
      },
      secondInformation: {
        text: 'Romantisma darbs: Vilis Plūdonis "Atraitnes dēls"',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=348',
          },
        ],
      },
      thirdInformation: {
        text: 'Romantisma darbs: Jānis Poruks "Kauja pie Knipskas"',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl:
              'https://letonika.lv/literatura/Reader.aspx?r=154&#2191034/I:4/P:1',
          },
        ],
      },
    },
    {
      subtitle:
        '<span style="font-weight: bold; margin-left: 21px;">Tautiskajam romantismam</span> ir raksturīgs idealizēts tautas senatnes tēlojums, folkloras tēlu izmantojums.',
      information: {
        text: 'Tautiskā romantisma darbs: Andrejs Pumpurs "Lāčplēsis"',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2194401&r=163',
          },
        ],
      },
      secondInformation: {
        text: 'Tautiskā romantisma darbs: Ausekļa dzeja',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=151',
          },
        ],
      },
    },
    {
      subtitle:
        '<span style="font-weight: bold; margin-left: 21px;">Jaunromantismā</span> (arī neoromantismā) ir Izmantoti romantismam raksturīgie pretstati, akcentētas panteisma (panteisms  – reliģiski filozofiska mācība, kas dievu identificē ar dabu un uzskata dabu par dieva iemiesojumu) idejas.',
      information: {
        readMoreLabel: 'Lasīt vairāk par jaunromantismu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl:
              'https://letonika.lv/literatura/Section.aspx?f=1&id=2194401&r=163',
          },
          {
            linkLabel: 'letonika.lv enciklopēdijā',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=11662&r=1',
          },
        ],
      },
      secondInformation: {
        text: 'Jaunromantisma darbs: Kārlis Skalbe " Kā es braucu Ziemeļmeitas lūkoties"',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=290',
          },
        ],
      },
    },
    {
      title:
        '<div style="height: 28px; background: #E6E2CE; font-family: RobotoBold; font-size: 20px; padding-left:20px;";>REĀLISMS</div>',
      information: {
        text: '<span style="font-weight: bold;">Reālismam</span> (19.gs. vidus līdz mūsdienām) ir raksturīgs patiess dzīves īstenības tēlojums, īpašu uzmanību pievēršot sociālajai videi un tipiskajam ikdienā.',
        readMoreLabel: 'Lasīt vairāk par reālismu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=32832&r=4',
          },
          {
            linkLabel: 'letonika.lv enciklopēdijā',
            linkUrl: 'https://letonika.lv/groups/default.aspx?cid=22866&r=1',
          },
          {
            linkLabel: 'Nacionālajā enciklopēdijā',
            linkUrl:
              'https://enciklopedija.lv/skirklis/3704-re%C4%81lisms,-literat%C5%ABr%C4%81',
          },
        ],
      },
      secondInformation: {
        text: 'Reālisma darbs: Brāļi Kaudzītes "Mērnieku laiki"',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'letonika.lv Lasītavā',
          },
        ],
      },
      thirdInformation: {
        text: 'Reālisma darbs: Rūdolfs Blaumanis "Skroderdienas Silmačos"',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=164',
          },
        ],
      },
    },
    {
      subtitle:
        '<span style="font-weight: bold; margin-left: 21px;">Psiholoģiskajā reālismā</span> ir akcentēti personiskie pārdzīvojumi, ko izraisa sabiedrība.',
      information: {
        text: 'Psiholoģiskā reālisma darbs: Rūdolfs Blaumanis "Nāves ēnā"',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl:
              'https://letonika.lv/literatura/Reader.aspx?r=150&#2191090/I:3/P:1',
          },
        ],
      },
      secondInformation: {
        text: 'Psiholoģiskā reālisma darbs: Regīna Ezera "Cilvēkam vajag suni"',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=278',
          },
        ],
      },
    },
    {
      subtitle:
        '<span style="font-weight: bold; margin-left: 21px;">Sociālajam reālismam</span> ir raksturīgi vispārināti, tipiski tēli, izcelta indivīda sociālā piederība.',
      information: {
        text: 'Sociālā reālisma darbs: Eduarda Veidenbauma dzeja',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=166',
          },
        ],
      },
    },
    {
      title:
        '<div style="height: 28px; background: #FFFBA7; font-family: RobotoBold; font-size: 20px; padding-left:20px;";>MODERNISMS</div>',
      information: {
        text: '<span style="font-weight: bold;">Modernismam</span> (20.gs. sākums līdz mūsdienām) ir raksturīgs tradicionālo vērtību noliegums, eksperimentāli jaunas formas meklējumi, īpaši akcentēta autora subjektīvā apziņa, asociācijas.',
        readMoreLabel: 'Lasīt vairāk par modernismu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=4&id=967841&g=1',
          },
          {
            linkLabel: 'letonika.lv enciklopēdijā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=1&id=950941&g=1',
          },
        ],
      },
      secondInformation: {
        text: 'Modernisma darbs: Ojāra Vācieša dzeja',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=306',
          },
        ],
      },
      thirdInformation: {
        text: 'Modernisma darbs: Imanta Ziedoņa epifānijas',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=251',
          },
        ],
      },
    },
    {
      subtitle:
        '<span style="font-weight: bold; margin-left: 21px;">Simbolismam</span> ir raksturīga pasaules intuitīva izzināšana. Literārā darba ideja izteikta vispārināti, simbolu veidā.',
      information: {
        readMoreLabel: 'Lasīt vairāk par simbolismu: ',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=4&id=968110&g=1',
          },
          {
            linkLabel: 'letonika.lv enciklopēdijā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=1&id=958077&g=1',
          },
        ],
      },
      secondInformation: {
        text: 'Simbolisma darbs: Rainis "Uguns un nakts"',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=210',
          },
        ],
      },
    },
    {
      subtitle:
        '<span style="font-weight: bold; margin-left: 21px;">Impresionismam</span> ir raksturīga acumirkļa iespaidu fiksācija, pirmais iespaids kā noteicošais. Svarīgi izjust, iedomāties, asociatīvi parādīt. Stils aprauts, raksturīga domas nepabeigtība.',
      information: {
        readMoreLabel: 'Lasīt vairāk par impresionismu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=4&id=967643&g=1',
          },
          {
            linkLabel: 'letonika.lv enciklopēdijā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=1&id=942194&g=1',
          },
        ],
      },
      secondInformation: {
        text: 'Impresionisma darbs: Jānis Akuraters "Kalpa zēna vasara"',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=223',
          },
        ],
      },
    },
    {
      subtitle:
        '<span style="font-weight: bold; margin-left: 21px;">Imažinismam</span> ir raksturīga brīvā forma (verlibrs), dažādu  tēlu virknējums. Liela uzmanība pievērsta ārējās pasaules spilgtam tēlainam atveidojumam. Dominē redzes gleznas.',
      information: {
        readMoreLabel: 'Lasīt vairāk par imažinismu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=4&id=967641&g=1',
          },
          {
            linkLabel: 'letonika.lv enciklopēdijā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=1&id=942174&g=1',
          },
        ],
      },
      secondInformation: {
        text: 'Imažinisma darbs: Aleksandra Čaka dzeja',
        readMoreLabel: 'Lasīt darbu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Lasītavā',
            linkUrl: 'https://letonika.lv/literatura/Reader.aspx?r=249',
          },
        ],
      },
    },
    {
      subtitle:
        '<span style="font-weight: bold; margin-left: 21px;">Ekspresionisma</span> darbos centrā ir izvirzīts sakāpināts mākslinieka subjektīvais pasaules skatījums, traģiska dzīves izjūta, apvienota ar sociālo orientāciju. Galvenais varonis ir vispārināts "es", kura uzdevums ir izkliegt cilvēcību un protestu pret vardarbību.',
      information: {
        readMoreLabel: 'Lasīt vairāk par ekspresionismu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=4&id=967512&g=1',
          },
          {
            linkLabel: 'letonika.lv enciklopēdijā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=1&id=938352&g=1',
          },
        ],
      },
      secondInformation: {
        text: 'Ekspresionisma darbs: Pēteris Ērmanis "Es sludinu"',
        readMoreLabel: 'Lasīt par autoru un darbu:',
        readMoreLinks: [
          {
            linkLabel: 'literatura.lv vietnē',
            linkUrl:
              'https://www.literatura.lv/he/person/Peteris-Ermanis/872821',
          },
        ],
      },
    },
    {
      title:
        '<div style="height: 28px; background: #CBEAC6; font-family: RobotoBold; font-size: 20px; padding-left:20px;";>POSTMODERNISMS</div>',
      information: {
        text: '<span style="font-weight: bold;">Postmodernismam</span> (20.gs. vidus līdz mūsdienām) ir raksturīga daudznozīmība, citātiskums un fragmentācija, ironija par pagātnes stiliem. Apvienoti atšķirīgi elementi (kolāža). Apšaubīta mākslas vērtību, tradīciju stabilitāte.',
        readMoreLabel: 'Lasīt vairāk par postmodernismu:',
        readMoreLinks: [
          {
            linkLabel: 'letonika.lv Dzejas vārdnīcā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=4&id=967983&g=1',
          },
          {
            linkLabel: 'letonika.lv enciklopēdijā',
            linkUrl:
              'https://letonika.lv/groups/default.aspx?r=1&id=954574&g=1',
          },
          {
            linkLabel: 'satori.lv vietnē',
            linkUrl: 'https://satori.lv/article/musdienas-pec-postmodernisma',
          },
        ],
      },
      secondInformation: {
        text: 'Postmodernisma darbs: Aivars Ozoliņš "Dukts"',
        readMoreLabel: 'Lasīt par darbu un autoru:',
        readMoreLinks: [
          {
            linkLabel: 'G.Bereļa recenzija',
            linkUrl:
              'https://berelis.wordpress.com/category/recenzijas/ozolins-aivars-dukts-ii/',
          },
        ],
      },
    },
  ];

  createGraph() {
    //Tabulas kreisā kolonna, virsraksti
    var groups = new vis.DataSet([
      {
        id: 1,
        content: '<b>Klasicisms</b>',
        value: 1,
        className: 'a1_bg',
        title: '17.gs. vidus līdz 18.gs. beigas',
      },
      {
        id: 2,
        content: '<b>Apgaismes reālisms </b>',
        value: 2,
        className: 'a2_bg',
        title: '18.gs. otrā puse līdz 19.gs. vidus',
      },
      {
        id: 3,
        content: '<b>Sentimentālisms </b>',
        value: 3,
        className: 'a3_bg',
        title: '18.gs. beigas līdz 20.gs. sākums',
      },
      {
        id: 4,
        content:
          '<b>Romantisms </b></br><i>Apakšvirzieni</i>:</br>tautiskais romantisms </br>jaunromantisms',
        value: 4,
        className: 'a4_bg',
        title: '19.gs. vidus līdz 20.gs. sākums',
      },
      {
        id: 5,
        content:
          '<b>Reālisms </b></br><i>Apakšvirzieni</i>:</br>psiholoģiskais reālisms  </br>sociālais reālisms',
        value: 5,
        className: 'a5_bg',
        title: '19.gs. vidus līdz mūsdienām',
      },
      {
        id: 6,
        content:
          '<b>Modernisms </b></br><i>Apakšvirzieni</i>:</br>simbolisms</br>impresionisms </br>imažinisms</br>ekspresionisms',
        value: 6,
        className: 'a6_bg',
        title: '20.gs. sākums līdz mūsdienām',
      },
      {
        id: 7,
        content: '<b>Postmodernisms</b>',
        value: 7,
        className: 'a7_bg',
        title: '20.gs. vidus līdz mūsdienām',
      },
      {
        id: 8,
        content: '<b>Kultūras kanona vērtības</b>',
        value: 8,
        className: 'a8_bg',
      },
      {
        id: 9,
        content: '<b>Vēstures notikumi</b>',
        value: 9,
        className: 'a9_bg',
      },
    ]);
    // create a dataset with items
    // note that months are zero-based in the JavaScript Date object, so month 3 is April

    var items = new vis.DataSet([
      //Coloring for backgrounds

      {
        id: 'Klasicisms_bg',
        content: '',
        start: '1650-12-12',
        end: '1800-12-12',
        type: 'background',
        className: 'a1_bg',
        group: 1,
      },
      {
        id: 'Apgaismes_reālisms_bg',
        content: '',
        start: '1760-12-12',
        end: '1850-12-12',
        type: 'background',
        className: 'a2_bg',
        group: 2,
      },
      {
        id: 'Sentimentālisms_bg',
        content: '',
        start: '1770-12-12',
        end: '1915-12-12',
        type: 'background',
        className: 'a3_bg',
        group: 3,
      },
      {
        id: 'Romantisms_bg',
        content: '',
        start: '1850-12-12',
        end: '1915-12-12',
        type: 'background',
        className: 'a4_bg',
        group: 4,
      },
      {
        id: 'Reālisms_bg',
        content: '',
        start: '1850-12-12',
        end: '2021-12-12',
        type: 'background',
        className: 'a5_bg',
        group: 5,
      },
      {
        id: 'Modernisms_bg',
        content: '',
        start: '1900-12-12',
        end: '2021-12-12',
        type: 'background',
        className: 'a6_bg',
        group: 6,
      },
      {
        id: 'Postmodernisms_bg',
        content: '',
        start: '1950-12-12',
        end: '2021-12-12',
        type: 'background',
        className: 'a7_bg',
        group: 7,
      },
      {
        id: 'Kultūra_bg',
        content: '',
        start: '1650-12-12',
        end: '2021-12-12',
        type: 'background',
        className: 'a8_bg',
        group: 8,
      },
      {
        id: 'Vēsture_bg',
        content: '',
        start: '1650-12-12',
        end: '2021-12-12',
        type: 'background',
        className: 'a9_bg',
        group: 9,
      },

      // ID1 Klasicisms
      {
        id: 101,
        content:
          "K.Fīrekers, ''Dievs Kungs ir mūsu stiprā pils'' (tulkojums) un citas baznīcas dziesmas",
        start: '1671-12-12',
        title: '1671., klasicisms',
        //className: "klasicisms_points",
        group: 1,
        type: 'point',
      },

      // ID2 Apgaismes reālisms
      {
        id: 201,
        content:
          "G.Merķelis, ''Latvieši, sevišķi Vidzemē filozofiskā gadsimta beigās''",
        start: '1796-12-12T23:00:00',
        title: '1796., apgaismes reālisms',
        //className: "apgaismesrealisms_points",
        group: 2,
        type: 'point',
      },

      // ID3 Sentimentālisms
      {
        id: 301,
        content: "Ķikuļa Jēkabs, ''Viena Vidzemes cietumnieku bēdu dziesma''",
        start: '1777-12-12T23:00:00',
        title: '1777., sentimentālisms',
        group: 3,
        type: 'point',
      },

      // ID4  Romantisms
      {
        id: 401,
        content: "V.Plūdons, ''Atraitnes dēls''",
        start: '1901-12-12T23:00:00',
        group: 4,
        title: '1901., romantisms',
        type: 'point',
      },
      {
        id: 402,
        content: "J.Poruks, ''Kauja pie Knipskas''",
        start: '1897-12-12T23:00:00',
        group: 4,
        title: '1897., romantisms',
        type: 'point',
      },
      {
        id: 403,
        content: "A.Pumpurs, ''Lāčplēsis''",
        start: '1888-12-12T23:00:00',
        group: 4,
        title: '1888., tautiskais romantisms',
        type: 'point',
      },
      {
        id: 404,
        content: 'Auseklis, dzeja',
        start: '1873-12-12T23:00:00',
        group: 4,
        title: '1873., tautiskais romantisms',
        type: 'point',
      },
      {
        id: 405,
        content: "K.Skalbe, ''Kā es braucu Ziemeļmeitas lūkoties''",
        start: '1904-12-12T23:00:00',
        group: 4,
        title: '1904., jaunromantisms (arī neoromantisms)',
        type: 'point',
      },

      // ID5  Reālisms
      {
        id: 501,
        content: "brāļi Kaudzītes, ''Mērnieku laiki''",
        start: '1879-12-12T23:00:00',
        group: 5,
        title: '1879., reālisms',
        type: 'point',
      },
      {
        id: 502,
        content: "R.Blaumanis, ''Skroderdienas Silmačos''",
        start: '1901-12-12T23:00:00',
        group: 5,
        title: '1901., reālisms',
        type: 'point',
      },
      {
        id: 503,
        content: "R.Blaumanis, ''Nāves ēnā''",
        start: '1899-12-12T23:00:00',
        group: 5,
        title: '1899., psiholoģiskais reālisms',
        type: 'point',
      },
      {
        id: 504,
        content: "R.Ezera, ''Cilvēkam vajag suni''",
        start: '1975-12-12T23:00:00',
        group: 5,
        title: '1975., psiholoģiskais reālisms',
        type: 'point',
      },
      {
        id: 505,
        content: 'E.Veidenbaums, dzeja',
        start: '1892-12-12T23:00:00',
        group: 5,
        title: '1892., sociālais reālisms',
        type: 'point',
      },

      // ID6  Modernisms
      {
        id: 601,
        content: 'O.Vācietis, dzeja',
        start: '1978-12-12T23:00:00',
        group: 6,
        title: '1978., 1982., modernisms',
        type: 'point',
      },
      {
        id: 602,
        content: 'I.Ziedonis, epifānijas',
        start: '1971-12-12T23:00:00',
        group: 6,
        title: '1971., modernisms',
        type: 'point',
      },
      {
        id: 603,
        content: "Rainis ''Uguns un nakts''",
        start: '1905-12-12T23:00:00',
        group: 6,
        title: '1905., simbolisms',
        type: 'point',
      },
      {
        id: 604,
        content: "J.Akuraters, ''Kalpa zēna vasara''",
        start: '1908-12-12T23:00:00',
        group: 6,
        title: '1908., impresionisms',
        type: 'point',
      },
      {
        id: 605,
        content: 'A.Čaks, dzeja',
        start: '1932-12-12T23:00:00',
        group: 6,
        title: '1932., imažinisms',
        type: 'point',
      },
      {
        id: 606,
        content: "P.Ērmanis, ''Es sludinu''",
        start: '1920-12-12T23:00:00',
        group: 6,
        title: '1920., ekspresionisms',
        type: 'point',
      },

      // ID7  Postmodernisms
      {
        id: 701,
        content: "A.Ozoliņš, ''Dukts''",
        start: '1991-12-12T23:00:00',
        group: 7,
        title: '1991., postmodernisms',
        type: 'point',
      },
      // ID8  Kultūras kanona vērtības
      {
        id: 801,
        content: 'Dziesmu svētki',
        start: '1873-12-12T23:00:00',
        group: 8,
        title: '1873., Pirmie Vispārīgie latviešu dziedāšanas svētki',
        type: 'point',
      },
      {
        id: 802,
        content: "''Latvju dainas''",
        start: '1897-12-12T23:00:00',
        group: 8,
        title: '1894.-1915., tautasdziesmu izdevumi K.Barona kārtojumā',
        type: 'point',
      },
      {
        id: 803,
        content: "''Nāves ēnā''",
        start: '1971-12-12T23:00:00',
        group: 8,
        title: '1971., spēlfilma',
        type: 'point',
      },
      {
        id: 804,
        content: "''Baltā grāmata''",
        start: '1914-12-12T23:00:00',
        group: 8,
        title: '1914.-1921., J.Jaunsudrabiņa tēlojumi',
        type: 'point',
      },
      {
        id: 805,
        content: "''Aija''",
        start: '1911-12-12T23:00:00',
        group: 8,
        title: '1911.-1925., J.Jaunsudrabiņa triloģija',
        type: 'point',
      },
      {
        id: 806,
        content: "''Lāčplēsis''",
        start: '1930-12-12T23:00:00',
        group: 8,
        title: '1930., spēlfilma',
        type: 'point',
      },
      {
        id: 807,
        content: "''Es ieeju sevī''",
        start: '1968-12-12T23:00:00',
        group: 8,
        title: '1968., I.Ziedoņa dzejas krājums',
        type: 'point',
      },
      {
        id: 808,
        content: 'U.Bērziņa dzeja',
        start: '1980-12-12T23:00:00',
        group: 8,
        title: "1980., pirmais krājums ''Piemineklis kazai''",
        type: 'point',
      },
      {
        id: 809,
        content: "''Gadu gredzeni''",
        start: '1969-12-12T23:00:00',
        group: 8,
        title: '1969., V.Belševicas dzejas krājums',
        type: 'point',
      },
      {
        id: 810,
        content: "''Dzeltu laiks''",
        start: '1987-12-12T23:00:00',
        group: 8,
        title: '1987., V.Belševicas dzejas krājums',
        type: 'point',
      },
      {
        id: 811,
        content: "''Bille''",
        start: '1995-12-12T23:00:00',
        group: 8,
        title: '1995.-1999., V.Belševicas triloģija',
        type: 'point',
      },
      {
        id: 812,
        content: "''Taureņu uzbrukums''",
        start: '1988-12-12T23:00:00',
        group: 8,
        title: '1988., I.Ziedoņa dzejas krājums',
        type: 'point',
      },
      {
        id: 813,
        content: 'Brīvības piemineklis',
        start: '1935-12-12T23:00:00',
        group: 8,
        title: '1931.-1935., arhitekts K.Zāle',
        type: 'point',
      },
      {
        id: 814,
        content: "''Ziemas pasakas''",
        start: '1913-12-12T23:00:00',
        group: 8,
        title: '1913., K.Skalbes krājums',
        type: 'point',
      },
      {
        id: 815,
        content: "''Gals un sākums''",
        start: '1912-12-12T23:00:00',
        group: 8,
        title: '1912., Raiņa dzejas krājums',
        type: 'point',
      },
      {
        id: 816,
        content: "''Smalkās kaites''",
        start: '1937-12-12T23:00:00',
        group: 8,
        title: '1937., E.Ādamsona noveļu krājums',
        type: 'point',
      },
      {
        id: 817,
        content: "''Pērļu zvejnieks''",
        start: '1895-12-12T23:00:00',
        group: 8,
        title: '1895., J.Poruka garstāsts',
        type: 'point',
      },
      {
        id: 818,
        content: "''Latviešu valodas vārdnīca''",
        start: '1923-12-12T23:00:00',
        group: 8,
        title:
          '1923.-1932., K.Mīlenbaha redakcijā ar J.Endzelīna papildinājumiem',
        type: 'point',
      },
      {
        id: 819,
        content: "''Indrāni''",
        start: '1904-12-12T23:00:00',
        group: 8,
        title: '1904., R.Blaumaņa luga',
        type: 'point',
      },
      {
        id: 820,
        content: "''Zemdegas''",
        start: '1977-12-12T23:00:00',
        group: 8,
        title: '1977., R.Ezeras romāns',
        type: 'point',
      },
      {
        id: 821,
        content: "''Mūžības skartie''",
        start: '1937-12-12T23:00:00',
        group: 8,
        title: '1937.-1939., A.Čaka varoņeposs',
        type: 'point',
      },
      {
        id: 822,
        content: 'Liepājas Sv. Annas baznīcas retabls',
        start: '1697-12-12T23:00:00',
        group: 8,
        title: '1697., N.Sefrenss',
        type: 'point',
      },
      {
        id: 823,
        content: 'Johans Gotfrīds Mītels',
        start: '1748-12-12T23:00:00',
        group: 8,
        title: '1728.-1788., ievērojamākais Rīgas vācu komponists',
        type: 'point',
      },

      {
        id: 824,
        content: 'Rundāles pils',
        start: '1736-12-12T23:00:00',
        group: 8,
        title: '1736.-1768.',
        type: 'point',
      },

      {
        id: 825,
        content: 'Kristofs Hāberlands',
        start: '1770-12-12T23:00:00',
        group: 8,
        title: '1750.-1803.,  modernās Rīgas būvmeistars',
        type: 'point',
      },

      {
        id: 826,
        content: 'Reinholds Šmēlings',
        start: '1870-12-12T23:00:00',
        group: 8,
        title: '1840.-1917., Rīgas pilsētas galvenais arhitekts',
        type: 'point',
      },

      {
        id: 827,
        content: "''Pūt, vējiņi!''",
        start: '1884-12-12T23:00:00',
        group: 8,
        title: '1884., A.Jurjāna apdare tautas dziesmai',
        type: 'point',
      },

      // ID9  Vēstures notikumi
      {
        id: 901,
        content: 'Ziemeļu karš',
        start: '1700-12-12T23:00:00',
        end: '1721-12-12T23:00:00',
        group: 9,
        title: '1700.-1721.<br/> Vidzeme un Rīga nokļūst Krievijas pārvaldībā',
        type: 'box',
      },

      {
        id: 902,
        content: 'Lielais mēris',
        start: '1710-12-12T23:00:00',

        group: 9,
        title:
          '1710.-1711. <br/>lielākā latviešu tautas demogrāfiskā katastrofa',
        type: 'point',
      },

      {
        id: 903,
        content: 'Krievijas-Polijas-Zviedrijas karš',
        start: '1654-12-12T23:00:00',
        end: '1667-12-12T23:00:00',
        group: 9,
        title:
          '1654.-1667.<br/> Vidzeme paliek Zviedrijas valdījumā, Latgale - Polijas',
        type: 'box',
      },

      {
        id: 904,
        content: "''Tā svēta grāmata''",
        start: '1685-12-12T23:00:00',

        group: 9,
        title:
          '1685. <br/>pirmais pilnīgais Vecās un Jaunās Derības izdevums <br/>latviešu valodā; Ernsta Glika tulkojumā',
        type: 'point',
      },
      {
        id: 905,
        content: 'Jēkabs Ketlers',
        start: '1672-12-12T23:00:00',
        group: 9,
        title: '1610.-1682.<br/>Kurzemes un Zemgales hercogs 1642.-1682.',
        type: 'point',
      },
      {
        id: 906,
        content: "''Bildu ābice''",
        start: '1787-12-12T23:00:00',
        group: 9,
        title:
          '1787.<br/> pirmā ilustrētā ābece latviešu valodā; Vecā Stendera izdevums',
        type: 'point',
      },

      {
        id: 907,
        content: 'Kurzemes un Zemgales hercogiste',
        start: '1670-12-12T23:00:00',
        end: '1795-12-12T23:00:00',
        group: 9,
        title:
          '1561.-1795.<br/> izveidojas pēc Livonijas sabrukuma,<br/>1795.g. tika pievienota Krievijai',
        type: 'box',
      },

      {
        id: 908,
        content: "''Ilgi gaidītais sprediķu krājums''",
        start: '1654-12-12T23:00:00',
        group: 9,
        title:
          '1654.<br/>  pirmais prozas oriģinālsacerējums latviešu valodā; G.Manceļa sprediķi',
        type: 'point',
      },

      {
        id: 910,
        content: '1905. gada revolūcija',
        start: '1905-12-12T23:00:00',
        group: 9,
        title:
          '1905.<br/> plaši nemieri, kas aptvēra gandrīz visu Krievijas impēriju',
        type: 'point',
      },
      {
        id: 911,
        content: 'Baltijas skolotāju seminārs',
        start: '1870-12-12T23:00:00',

        group: 9,
        title:
          '1870.-1919.<br/> pirmā valsts pedagoģiskā mācību iestāde Latvijā',
        type: 'point',
      },

      {
        id: 912,
        content: 'Latvijas Sociāldemokrātijas pirmsākumi',
        start: '1899-12-12T23:00:00',
        end: '1906-12-12T23:00:00',
        group: 9,
        title: '1899.-1906.',
        type: 'point',
      },

      {
        id: 913,
        content: 'Jaunlatvieši',
        start: '1860-12-12T23:00:00',
        end: '1890-12-12T23:00:00',
        group: 9,
        title: '19.gs. otrā puse <br/>sabiedriska nacionāla kustība Latvijā',
        type: 'box',
      },

      {
        id: 914,
        content: 'Zemnieku brīvlaišana',
        start: '1820-12-12T23:00:00',
        end: '1861-12-12T23:00:00',
        group: 9,
        title:
          'Dzimtbūšanas atcelšana Latvijā<br/>1818.g. - Kurzemē (un Zemgalē), 1819.g. - Vidzemē, 1861.g. - Latgalē',
        type: 'box',
      },
      {
        id: 915,
        content: 'Napoleona I karagājiens uz Krieviju',
        start: '1812-12-12T23:00:00',
        group: 9,
        title: '1812.<br/> skāra arī lielu daļu Latvijas teritorijas',
        type: 'point',
      },
      {
        id: 916,
        content: 'Pētera akadēmija',
        start: '1775-12-12T23:00:00',

        group: 9,
        title:
          '1775.-1806.<br/> mācību un zinātniska iestāde Jelgavā, ko nodibināja <br/>Kurzemes un Zemgales hercogs Pēteris Bīrons',
        type: 'point',
      },
      {
        id: 917,
        content: 'Krāslavas garīgais seminārs',
        start: '1757-12-12T23:00:00',

        group: 9,
        title:
          '1757.-1844.<br/> pirmā augstākā mācību iestāde Latgalē, atvērta par muižniecības līdzekļiem',
        type: 'point',
      },

      {
        id: 919,
        content: '15. maija valsts apvērsums',
        start: '1934-12-12T23:00:00',
        group: 9,
        title:
          '1934.<br/> K.Ulmaņa vadībā tika apturēta Satversmes darbība, <br/>atlaista Saeima, slēgtas visas politiskās partijas',
        type: 'point',
      },

      {
        id: 922,
        content: 'Pirmais pasaules karš',
        start: '1914-12-12T23:00:00',
        end: '1918-12-12T23:00:00',
        group: 9,
        title:
          '1914.-1918.<br/> karš starp diviem valstu grupējumiem – <br/>Antanti un Vācijas un Austroungārijas bloku',
        type: 'box',
      },

      {
        id: 923,
        content: 'Latvijas Republikas pasludināšana',
        start: '1918-12-12T23:00:00',
        group: 9,
        title: '1918.',
        type: 'point',
      },
      {
        id: 924,
        content: 'Padomju vara Latvijā',
        start: '1919-12-12T23:00:00',
        end: '1920-12-12T23:00:00',
        group: 9,
        title:
          '1918.-1920.<br/> Maskavā izveidotās P.Stučkas valdības uzdevums bija <br/>radīt Latvijā sociālistisku padomju republiku',
        type: 'point',
      },

      {
        id: 909,
        content: 'Februāra revolūcija',
        start: '1917-12-12T23:00:00',

        group: 9,
        title: '1917.<br/> Kievijas cars Nikolajs II atsakās no troņa',
        type: 'point',
      },

      {
        id: 920,
        content: 'Deportācijas',
        start: '1941-12-12T23:00:00',
        group: 9,
        title:
          '1941.-1949.<br/> Latvijas pilsoņu masveidīga administratīva <br/>izsūtīšana uz KPFSR attāliem novadiem',
        type: 'point',
      },

      {
        id: 921,
        content: 'Latvijas okupācija',
        start: '1940-12-12T23:00:00',
        group: 9,
        title:
          '1940.<br/> neatkarīgās Latvijas valsts pastāvēšanu pārtrauca <br/>Padomju Savienības īstenotā okupācija un aneksija',
        type: 'point',
      },

      {
        id: 918,
        content: 'Otrais pasaules karš',
        start: '1942-12-12T23:00:00',
        end: '1945-12-12T23:00:00',
        group: 9,
        title: '1939.-1945.<br/> Latvijas teritorijā 1941.-1945.',
        type: 'box',
      },

      {
        id: 925,
        content: 'Latvija PSRS sastāvā',
        start: '1952-12-12T23:00:00',
        end: '1991-12-12T23:00:00',

        group: 9,
        title: '1945.-1991.<br/>padomju otrreizējās okupācijas Latvijā posms',
        type: 'box',
      },

      {
        id: 926,
        content: 'Latvijas Republikas neatkarības atgūšana',
        start: '1991-12-12T23:00:00',
        group: 9,
        title:
          '1991.<br/> LR Augstākā Padome pieņem konstitucionālo <br/>likumu par LR valstisko statusu',
        type: 'point',
      },

      {
        id: 927,
        content: 'Barikāžu dienas Rīgā',
        start: '1991-12-12T23:00:00',
        group: 9,
        title:
          '1991.<br/>Latvijas iedzīvotāji aizsargā valsts stratēģiski <br/>svarīgos objektus no PSRS armijas',
        type: 'point',
      },

      {
        id: 928,
        content: 'Latvijas Tautas fronte',
        start: '1988-12-12T23:00:00',

        group: 9,
        title:
          '1988.-1997.<br/>sabiedriski politiska organizācija neatkarīgas un <br/>demokrātiskas Latvijas valsts atjaunošanai',
        type: 'point',
      },

      {
        id: 929,
        content: "Gorbačova''perestroika'' ",
        start: '1985-12-12T23:00:00',

        group: 9,
        title:
          '1985.-1991.<br/> jauns politiskās un saimnieciskās dzīves pārbūves kurss PSRS',
        type: 'point',
      },

      {
        id: 930,
        content: 'Brīvmūrniecība',
        start: '1750-12-12T23:00:00',

        group: 9,
        title:
          '18.-20.gs.<br/>starptautiska reliģiski ētiska kustība, kas izauga no viduslaiku <br/>mūrnieku brālībām un kļuva par nozīmīgu laikmeta sastāvdaļu',
        type: 'box',
      },

      {
        id: 931,
        content: 'Brāļu draudzes Vidzemē',
        start: '1729-12-12T23:00:00',

        group: 9,
        title:
          '18.-19.gs.<br/>(arī hernhūtiešu brāļu draudzes) - sākotnēji reliģiski sektantiska, <br/>vēlāk sociāli reliģiska kustība',
        type: 'box',
      },

      {
        id: 932,
        content: 'Latvija PSRS sastāvā',
        start: '1984-12-12T23:00:00',

        group: 9,
        title: '1945.-1991.<br/>padomju otrreizējās okupācijas Latvijā posms',
        type: 'box',
      },
    ]);

    //

    // create visualization
    var container = document.getElementById('visualization');
    var options = {
      // option groupOrder can be a property name or a sort function
      // the sort function must compare two groups and return a value
      //     > 0 when a > b
      //     < 0 when a < b
      //       0 when a == b

      groupOrder: function (a: any, b: any) {
        return a.value - b.value;
      },
      editable: false,
      clickToUse: true,
      start: '1650-01-10',
      end: '1800-02-10',
      showCurrentTime: false,
      moveable: true,
      zoomable: true,
      zoomMin: 1000 * 60 * 60 * 24 * 10000, // one day in milliseconds
      zoomMax: 1000 * 60 * 60 * 24 * 31 * 3 * 5000, // about three months in milliseconds

      min: new Date(1630, 0, 1), // lower limit of visible range
      max: new Date(2060, 0, 1), // upper limit of visible range
      timeAxis: { scale: 'year', step: 20 },
      margin: { item: 0 },
    };

    const timeline = new vis.Timeline(container);
    timeline.setOptions(options);
    timeline.setGroups(groups);
    timeline.setItems(items);

    /**
     * Move the timeline a given percentage to left or right
     * @param {Number} percentage   For example 0.1 (left) or -0.1 (right)
     */
    function move(percentage: number) {
      var range = timeline.getWindow();
      var interval = range.end - range.start;

      timeline.setWindow({
        start: range.start.valueOf() - interval * percentage,
        end: range.end.valueOf() - interval * percentage,
      });
    }

    // attach events to the navigation buttons
    this.zoomInEl.nativeElement.onclick = function () {
      timeline.zoomIn(0.3);
    };
    this.zoomOutEl.nativeElement.onclick = function () {
      timeline.zoomOut(0.3);
    };
    this.moveLeftEl.nativeElement.onclick = function () {
      move(0.5);
    };
    this.moveRightEl.nativeElement.onclick = function () {
      move(-0.5);
    };

    timeline.on('click', (params: any) => {
      console.log(params);
      let nodeValue = params.item;
      console.log(this.target);

      switch (nodeValue) {
        // Results
        // 0 Klasicisms
        // 1 Apgaismes reālisms
        // 2 Sentimentālisms
        // 3 Romantisms
        // 4 Tautiskajam romantismam ir raksturīgs...
        // 5 Jaunromantismā
        // 6 Reālisms
        // 7 Psiholoģiskajā reālismā
        // 8 Sociālais reālisms
        // 9 Modernisms
        // 10 Simbolismam ir raksturīga
        // 11 Impresionisms
        // 12 Imažinisms
        // 13 Ekspresionisms
        // 14 Postmodernisms

        // Klasicisms
        case 101:
          (this.target as any)._results[0].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        //Apgaismes reālisms
        case 201:
          (this.target as any)._results[1].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // Sentimentālisms
        case 301:
          (this.target as any)._results[2].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // Romantisms
        case 401:
          (this.target as any)._results[3].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 402:
          (this.target as any)._results[3].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 403:
          (this.target as any)._results[3].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 404:
          (this.target as any)._results[3].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 405:
          (this.target as any)._results[3].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // Reālisms
        case 501:
          (this.target as any)._results[6].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 502:
          (this.target as any)._results[6].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 503:
          (this.target as any)._results[6].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 504:
          (this.target as any)._results[6].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 504:
          (this.target as any)._results[6].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // Modernisms
        case 601:
          (this.target as any)._results[9].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 602:
          (this.target as any)._results[9].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 603:
          (this.target as any)._results[9].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 604:
          (this.target as any)._results[9].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 605:
          (this.target as any)._results[9].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        case 606:
          (this.target as any)._results[9].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // Postmodernisms
        case 701:
          (this.target as any)._results[14].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
      }
    });
  }

  constructor(public scriptsService: ScriptService) {
    this.scriptsService
      .load('laikagrafs')
      .then(() => {
        this.createGraph();
      })
      .catch((error: any) => console.log(error));
  }

  ngOnInit(): void {}
}
