<div class="col-12">
  <div class="row main-text">
    <div class="row section">
      <div
        [innerHTML]="this.linksContent | safe: 'html'"
        class="letonika-link"
        target="_blank"
      >
        {{ this.linksContent | safe: "html" }}
      </div>
    </div>
  </div>
</div>
