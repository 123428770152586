import { Component, OnInit } from '@angular/core';
import { AboutProjectBlockService } from './about-project-block.service';

@Component({
  selector: 'app-about-project-block',
  templateUrl: './about-project-block.component.html',
  styleUrls: ['./about-project-block.component.scss'],
})
export class AboutProjectBlockComponent implements OnInit {
  links!: any;
  linksContent!: any;
  constructor(private aboutProjectBlockService: AboutProjectBlockService) {}

  ngOnInit(): void {
    this.aboutProjectBlockService
      .getContentForAboutPage()

      .subscribe((response: any) => {
        this.links = response;
        this.linksContent = this.links.Body[0].HtmlBlock;
      });
  }
}
