import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  getFooterStyle() {
    if (this.router.url.includes('/literatura/')) {
      return 'oceanblue';
    } else if (this.router.url.includes('/literaturas-veidi-un-zanri')) {
      return 'mediumgreen';
    } else if (
      this.router.url.includes('/makslinieciskas-izteiksmes-lidzekli')
    ) {
      return 'bluegrey';
    } else if (
      this.router.url.includes('/riki-radosumam') ||
      this.router.url.includes('/laika-karte')
    ) {
      return 'mediumblue';
    } else if (this.router.url.includes('/darba-lapas')) {
      return 'fadedblue';
    } else if (
      this.router.url.includes('/virtualie-asistenti/uzmini-zanru-vai-darbu')
    ) {
      return 'darkgreen';
    } else if (this.router.url.includes('/virtualie-asistenti')) {
      return 'darkgreen-no-top';
    } else {
      return 'mediumgreenhome';
    }
  }
}
