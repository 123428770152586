<div class="col colored-banner"></div>
<div class="row main-section">
  <div class="col-md-12 col-lg-6">
    <div id="mynetwork" #siteConfigNetwork></div>
  </div>
  <div class="col-md-12 col-lg-6 background">
    <ng-container *ngFor="let element of artisticExpressionBlock">
      <div class="row">
        <div
          class="row"
          [ngClass]="{
            'expression-type-heading': element.shouldBeDisplayedAsExample,
            'main-stylistic-figure': element.isMainStylisticFigure,
            'main-article': element.isMainArticle,
            'tropes-heading':
              !element.shouldBeDisplayedAsExample &&
              !element.isStylisticFigure &&
              !element.isMainArticle,
            'stylistic-figures': element.isStylisticFigure
          }"
        >
          <h5 class="title" #target>{{ element.artisticExpressionTitle }}</h5>
        </div>
        <div class="row definition">
          <p>{{ element.artisticExpressionDefinition }}</p>
        </div>
        <div
          class="row example"
          [ngClass]="{
            'stylistic-figures-example': element.isStylisticFigure,
            'tropes-example':
              !element.shouldBeDisplayedAsExample &&
              !element.isStylisticFigure &&
              !element.isMainArticle,
            'example-text': element.shouldBeDisplayedAsExample
          }"
        >
          <div class="col example-padding">
            <h5
              *ngIf="
                !element.shouldBeDisplayedAsExample && !element.isMainArticle
              "
              class="example-heading"
            >
              Piemērs:
            </h5>
            <h6 [innerHTML]="element.firstExample | safe: 'html'">
              {{ element.firstExample | safe: "html" }}
            </h6>
            <h6>{{ element.firstExampleAuthor }}</h6>
          </div>
          <div
            class="col-2 view-on-letonika"
            *ngIf="
              !element.shouldBeDisplayedAsExample && !element.isMainArticle
            "
          >
            Skatīt
            <a
              href="{{ element.firstExampleLink }}"
              class="letonika-link"
              target="_blank"
              >letonika.lv</a
            >
            lasītavā
          </div>
        </div>
        <div
          class="row example"
          [ngClass]="{
            'stylistic-figures-example': element.isStylisticFigure,
            'tropes-example':
              !element.shouldBeDisplayedAsExample && !element.isStylisticFigure
          }"
          *ngIf="element.secondExample"
        >
          <div class="col example-padding">
            <h5 class="example-heading">Piemērs:</h5>
            <h6 [innerHTML]="element.secondExample | safe: 'html'">
              {{ element.secondExample | safe: "html" }}
            </h6>
            <h6>{{ element.secondExampleAuthor }}</h6>
          </div>
          <div class="col-2 view-on-letonika">
            Skatīt
            <a
              href="{{ element.secondExampleLink }}"
              class="letonika-link"
              target="_blank"
              >letonika.lv</a
            >
            lasītavā
          </div>
        </div>
        <div class="row read-more-external" *ngIf="!element.isMainArticle">
          <div class="col-md-4">
            Lasi vairāk par {{ element.readMoreText }}:
          </div>
          <div class="col-md-4">
            <a
              href="{{ element.encyclopediaLink }}"
              target="_blank"
              class="national-encyclopedia"
            >
              Nacionālajā enciklopēdijā
            </a>
          </div>
          <div class="col-md-4">
            <a
              href="{{ element.letonikaLink }}"
              class="letonika-link"
              target="_blank"
              >letonika.lv</a
            >
            Dzejas vārdnīcā
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row worksheets mb-5">
      <h6 class="title">Darba lapas</h6>
      <a
        href="https://letonika.lv/kolekcija/?id=macibas&s=teorija#ValodasLidzeklji"
        class="worksheet-link"
        >Valodas līdzekļi teksta izteiksmīguma radīšanai</a
      >
    </div>
  </div>
</div>
