import { Component, OnInit } from '@angular/core';
import { MethodologyContent } from '../../views/methodology/methodology';
import { MethodologyBlockService } from './methodology-block.service';

@Component({
  selector: 'app-methodology-block',
  templateUrl: './methodology-block.component.html',
  styleUrls: ['./methodology-block.component.scss'],
})
export class MethodologyBlockComponent implements OnInit {
  links!: any;
  linksContent!: any;
  constructor(private methodologyBlockService: MethodologyBlockService) {}

  ngOnInit(): void {
    this.methodologyBlockService
      .getContentForMethodologyPage()
      .subscribe((response: MethodologyContent) => {
        this.links = response;
        this.linksContent = this.links.Body[0].HtmlBlock;
      });
  }
}
