import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ArticlesData, Literature, PagesData } from './literature';

@Injectable({
  providedIn: 'root',
})
export class LiteratureService {
  private baseUrl = environment.baseUrl;
  private literatureUrl = '/literature/';
  private pagesUrl = '/pages/';
  private articlesUrl = '/articles';

  // 'https://dmml-strapi-dev.azurewebsites.net/literature/atraitnes-dels'
  constructor(private http: HttpClient) {}

  getLiteratureData(id: string | null) {
    return this.http
      .get<Literature>(this.baseUrl + this.literatureUrl + id)
      .pipe(map((literatureData: Literature) => literatureData));
  }

  getPagesData(id: string | null) {
    return this.http
      .get<any>(this.baseUrl + this.pagesUrl + id)
      .pipe(map((pagesData: PagesData) => pagesData));
  }

  getAllArticlesData() {
    return this.http.get<ArticlesData[]>(this.baseUrl + this.articlesUrl);
  }
}
