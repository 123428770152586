import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodologyContent } from '../../views/methodology/methodology';

@Injectable({
  providedIn: 'root',
})
export class MethodologyBlockService {
  private url =
    'https://dmml-strapi-dev.azurewebsites.net/pages/pedagogiem-metodologija-linki';

  constructor(private http: HttpClient) {}

  getContentForMethodologyPage() {
    return this.http.get<MethodologyContent>(this.url);
  }
}
