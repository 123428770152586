import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-literature-block',
  templateUrl: './literature-block.component.html',
  styleUrls: ['./literature-block.component.scss'],
})
export class LiteratureBlockComponent implements OnInit {
  @Input() show!: boolean;

  @Input() title!: string;

  @Input() content!: string;

  @Input() type!: string;

  toggleViewMoreButton: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  toggleViewMore(): void {
    this.toggleViewMoreButton = !this.toggleViewMoreButton;
  }
}
