import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutProjectBlockComponent } from './layouts/blocks/about-project-block/about-project-block.component';
import { ArtisticExpressionBlockComponent } from './layouts/blocks/artistic-expression-block/artistic-expression-block.component';
import { HelperBlockComponent } from './layouts/blocks/helper-block/helper-block.component';
import { LiteratureTypesGenreBlockComponent } from './layouts/blocks/literature-types-genre-block/literature-types-genre-block.component';
import { MethodologyBlockComponent } from './layouts/blocks/methodology-block/methodology-block.component';
import { TimeMapComponent } from './layouts/blocks/time-map/time-map.component';
import { ToolsForCreativityBlockComponent } from './layouts/blocks/tools-for-creativity-block/tools-for-creativity-block.component';
import { WorksheetsBlockComponent } from './layouts/blocks/worksheets-block/worksheets-block.component';
import { BaseComponent } from './layouts/views/base/base.component';
import { HomeComponent } from './layouts/views/home/home.component';
import { LiteratureComponent } from './layouts/views/literature/literature.component';
import { MethodologyComponent } from './layouts/views/methodology/methodology.component';
import { AssistantChatComponent } from './layouts/views/virtual-assistants/assistant-chat/assistant-chat.component';
import { VirtualAssistantsComponent } from './layouts/views/virtual-assistants/virtual-assistants.component';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent,
      },
      { path: 'virtualie-asistenti', component: VirtualAssistantsComponent },
      {
        path: 'virtualie-asistenti/:id',
        component: AssistantChatComponent,
      },

      { path: 'literatura/:id', component: LiteratureComponent },
      {
        path: 'makslinieciskas-izteiksmes-lidzekli',
        component: ArtisticExpressionBlockComponent,
      },
      {
        path: 'literaturas-veidi-un-zanri',
        component: LiteratureTypesGenreBlockComponent,
      },
      {
        path: 'laika-karte',
        component: TimeMapComponent,
      },
      {
        path: 'riki-radosumam',
        component: ToolsForCreativityBlockComponent,
      },
      {
        path: 'darba-lapas',
        component: WorksheetsBlockComponent,
      },
      {
        path: 'pedagogiem',
        component: MethodologyComponent,
        children: [
          { path: 'paligs', component: HelperBlockComponent },
          { path: 'metodologija', component: MethodologyBlockComponent },
          { path: 'par-projektu', component: AboutProjectBlockComponent },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
