import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AboutContentBlock } from '../../views/methodology/methodology';

@Injectable({
  providedIn: 'root',
})
export class AboutProjectBlockService {
  private url =
    'https://dmml-strapi-dev.azurewebsites.net/pages/pedagogiem-par-projektu';

  constructor(private http: HttpClient) {}

  getContentForAboutPage() {
    return this.http.get<AboutContentBlock>(this.url);
  }
}
