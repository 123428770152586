<div class="col colored-banner"></div>
<div class="row main-section">
  <div class="col-md-12 col-lg-6">
    <div id="mynetwork" #siteConfigNetwork></div>
  </div>
  <div class="col-md-12 col-lg-6 background">
    <ng-container *ngFor="let element of literatureTypesAndGenresBlock">
      <div
        class="row expression-type-heading"
        [ngClass]="{
          dramaturgy: element.isDramaturgy,
          'epic-poetry': element.isEpicPoetry,
          'epic-poetry-subtitle': element.isEpicPoetrySubtitle,
          prose: element.isProse,
          fiction: element.isFiction,
          poetry: element.isPoetry,
          'prose-subtitle': element.isProseSubtitle,
          'dramaturgy-subtitle': element.isDramaturgySubtitle
        }"
      >
        <h5 class="title" #target>{{ element.typeOrGenreTitle }}</h5>
      </div>

      <div class="row text-content">
        <p [innerHTML]="element.text | safe: 'html'">
          {{ element.text | safe: "html" }}
        </p>
      </div>

      <!-- for first section of links -->
      <div class="row link-section">
        <div class="row link-title">
          {{ element.readMore.readMoreLabel }}
        </div>
        <ng-container *ngFor="let item of element.readMore.readMoreLinks">
          <div class="row">
            <a
              [attr.href]="item.linkUrl | safe: 'url'"
              class="letonika-link"
              target="_blank"
              >{{ item.linkLabel }}</a
            >
          </div>
        </ng-container>
      </div>
      <!-- for second section of links -->
      <div class="row link-section" *ngIf="element.readMoreSecond">
        <div class="row link-title">
          {{ element.readMoreSecond.readMoreLabel }}
        </div>

        <ng-container *ngFor="let item of element.readMoreSecond.readMoreLinks">
          <div class="row">
            <a
              [attr.href]="item.linkUrl | safe: 'url'"
              class="letonika-link"
              target="_blank"
            >
              {{ item.linkLabel }}
            </a>
          </div>
        </ng-container>
      </div>
      <!-- for third section for links -->
      <div class="row link-section" *ngIf="element.readMoreThird">
        <div class="row link-title">
          {{ element.readMoreThird.readMoreLabel }}
        </div>

        <ng-container *ngFor="let item of element.readMoreThird.readMoreLinks">
          <div class="row">
            <a
              [attr.href]="item.linkUrl | safe: 'url'"
              class="letonika-link"
              target="_blank"
            >
              {{ item.linkLabel }}
            </a>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div class="row worksheets mb-5">
      <h6 class="title">Darba lapas</h6>
      <a
        href="https://letonika.lv/kolekcija/?id=macibas&s=teorija#zanri"
        class="worksheet-link"
        target="_blank"
        >Literaturas žanru pārskats</a
      >
      <a
        href="https://letonika.lv/kolekcija/?id=macibas&s=teorija#zanri2"
        class="worksheet-link"
        target="_blank"
        >Atpazīsti literatūras žanrus</a
      >
    </div>
  </div>
</div>
