import { Component, OnInit } from '@angular/core';
import { VirtualAssistantText } from './virtual-assistant';
import { VirtualAssistantService } from './virtual-assistant.service';

@Component({
  selector: 'app-virtual-assistants',
  templateUrl: './virtual-assistants.component.html',
  styleUrls: ['./virtual-assistants.component.scss'],
})
export class VirtualAssistantsComponent implements OnInit {
  firstText!: VirtualAssistantText;
  firstContent!: string;
  Body!: string;
  secondText!: VirtualAssistantText;
  secondContent!: string;
  constructor(private virtualAssistantService: VirtualAssistantService) {}

  ngOnInit(): void {
    this.virtualAssistantService
      .getVirtualAssistantFirstText()
      .subscribe((response: VirtualAssistantText) => {
        this.firstText = response;
        this.firstContent = this.firstText.Body[0].HtmlBlock;
      });

    this.virtualAssistantService
      .getVirtualAssistantSecondText()
      .subscribe((response: VirtualAssistantText) => {
        this.secondText = response;
        this.secondContent = this.secondText.Body[0].HtmlBlock;
      });
  }
}
