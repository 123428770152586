<ng-container *ngIf="literatureData || pagesData">
  <app-literature-top
    [literatureData]="literatureData"
    [pagesData]="pagesData"
    [assistant]="true"
    [type]="passedDataType"
  ></app-literature-top>

  <ng-container *ngFor="let element of literatureBlock">
    <app-literature-block
      [title]="element.title"
      [show]="element.show"
      [content]="element.content"
      [type]="element.type"
    ></app-literature-block>
  </ng-container>
</ng-container>
