<div class="col colored-banner"></div>
<div class="col-md-12">
  <div class="row timeline">
    <div id="visualization" #visualizationNetwork>
      <div class="menu">
        <input type="button" id="zoomIn" #zoomIn value="Pietuvināt" />
        <input type="button" id="zoomOut" #zoomOut value="Attālināt" />
        <input type="button" id="moveLeft" #moveLeft value="Pa kreisi" />
        <input type="button" id="moveRight" #moveRight value="Pa labi" />
      </div>
    </div>
  </div>
  <div class="row main-section">
    <div class="col-md-12 background">
      <ng-container *ngFor="let element of timeMapInfoBlock">
        <div
          class="title-subtitle"
          [innerHTML]="element.title || element.subtitle | safe: 'html'"
          #target
          *ngIf="element.title || element.subtitle"
        >
          {{ element.title | safe: "html" || element.subtitle | safe: "html" }}
        </div>

        <div class="margin-between-external">
          <div
            class="text"
            [innerHTML]="element.information.text | safe: 'html'"
            *ngIf="element.information.text"
          >
            {{ element.information.text | safe: "html" }}
          </div>

          <div class="margin-between-content">
            {{ element.information.readMoreLabel }}
          </div>

          <div class="margin-between-external">
            <ng-container
              *ngFor="let item of element.information.readMoreLinks"
            >
              <div class="first-links">
                <a
                  [attr.href]="item.linkUrl | safe: 'url'"
                  class="letonika-link"
                  target="_blank"
                  >{{ item.linkLabel }}</a
                >
              </div>
            </ng-container>
          </div>
        </div>

        <div class="second-information" *ngIf="element.secondInformation">
          <div
            class="second-text"
            [innerHTML]="element.secondInformation.text | safe: 'html'"
          >
            {{ element.secondInformation.text | safe: "html" }}
          </div>
          <div
            class="second-read-more-label"
            [innerHTML]="element.secondInformation.readMoreLabel | safe: 'html'"
          >
            {{ element.secondInformation.readMoreLabel | safe: "html" }}
          </div>

          <ng-container
            *ngFor="let item of element.secondInformation.readMoreLinks"
          >
            <div class="second-links">
              <a
                [attr.href]="item.linkUrl | safe: 'url'"
                class="letonika-link"
                target="_blank"
              >
                {{ item.linkLabel }}
              </a>
            </div>
          </ng-container>
        </div>

        <div class="margin-between-content" *ngIf="element.thirdInformation">
          <div
            class="third-links"
            [innerHTML]="element.thirdInformation.text | safe: 'html'"
          >
            <h6>{{ element.thirdInformation.text | safe: "html" }}</h6>
          </div>
          <div class="">
            {{ element.thirdInformation.readMoreLabel }}
          </div>

          <ng-container
            *ngFor="let item of element.thirdInformation.readMoreLinks"
          >
            <div class="third-links">
              <a
                [attr.href]="item.linkUrl | safe: 'url'"
                class="letonika-link"
                target="_blank"
              >
                {{ item.linkLabel }}
              </a>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
