<!-- start of carousel -->
<div id="main-page">
  <div class="row carousel-section">
    <div class="col">
      <div
        id="carouselExampleDark"
        class="carousel carousel-dark slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active va-first" data-bs-interval="5500">
            <object
              type="image/svg+xml"
              data="assets/images/Cibinsh_banner_1.svg"
              class="d-block cibins-banner"
            ></object>
            <div class="carousel-caption d-md-block">
              <p>
                Es sarunāšos ar tevi un pārbaudīšu zināšanas par literatūras
                žanriem un darbiem!
              </p>
              <h5>Cibiņš</h5>
            </div>
          </div>
          <div class="carousel-item va-second" data-bs-interval="5500">
            <object
              type="image/svg+xml"
              data="assets/images/Skroderiene_banner_6.svg"
              class="d-block skroderiene-banner"
            ></object>
            <div class="carousel-caption d-md-block">
              <p>
                No Atraitnes dēla līdz Skroderdienām Silmačos - lasi, klausies,
                izzini un darbojies!
              </p>
              <h5>Skroderiene</h5>
            </div>
          </div>
          <div class="carousel-item va-third" data-bs-interval="5500">
            <object
              type="image/svg+xml"
              data="assets/images/Zvejnieks_banner_1.svg"
              class="d-block zvejnieks-banner"
            ></object>
            <div class="carousel-caption d-md-block">
              <p>
                Ar mani kopā literatūru mācīties ir kā Zaļgas zivju vezumu
                izsalkumā notiesāt!
              </p>
              <h5>Zvejnieks</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- end of carousel -->

  <div class="row row-cols-1 row-cols-md-3 card-grid">
    <ng-container *ngFor="let element of toolsForCreativityBlock">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div
              class="card-text-container"
              [ngStyle]="{ 'background-color': element.color }"
            >
              <h5 class="card-title">
                {{ element.title }}
              </h5>
            </div>

            <div class="external-links">
              <p
                class="card-text"
                [innerHTML]="element.description | safe: 'html'"
              >
                {{ element.description | safe: "html" }}
              </p>

              <ng-container *ngFor="let item of element.exampleLink">
                <span>Piemērs: </span>
                <a href="{{ item.linkUrl }}" target="_blank">{{
                  item.linkLabel
                }}</a>
              </ng-container>
              <br />
              <ng-container *ngFor="let item of element.secondExampleLink">
                <span>Piemērs: </span>
                <a href="{{ item.linkUrl }}" target="_blank">{{
                  item.linkLabel
                }}</a>
              </ng-container>
              <br />
              <ng-container *ngFor="let item of element.thirdExampleLink">
                <span>Piemērs: </span>
                <a href="{{ item.linkUrl }}" target="_blank">{{
                  item.linkLabel
                }}</a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
