import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import {
  ArticlesData,
  Literature,
  LiteratureBlock,
  LiteratureImage,
  PagesData,
} from './literature';
import { LiteratureService } from './literature.service';

@Component({
  selector: 'app-literature',
  templateUrl: './literature.component.html',
  styleUrls: ['./literature.component.scss'],
})
export class LiteratureComponent implements OnInit {
  baseUrl = environment.baseUrl;
  literatureData!: Literature;
  literatureImage!: LiteratureImage;
  literatureBlock: Array<LiteratureBlock> = [];
  pagesData!: PagesData;
  toggleViewMoreButton: boolean = false;

  constructor(
    private literatureService: LiteratureService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      if (paramMap.get('id') === 'uzmini-zanru-vai-darbu') {
        this.literatureService
          .getPagesData(paramMap.get('id'))
          .subscribe((resp) => {
            this.pagesData = resp;
          });
      } else if (paramMap.get('id') !== null) {
        this.literatureService
          .getLiteratureData(paramMap.get('id'))
          .subscribe((response) => {
            this.literatureData = response;
            this.literatureBlock = [];
            this.populateBlocks(this.literatureData);
          });
      }
    });
  }

  populateBlocks(literatureData: Literature): void {
    this.literatureBlock.push({
      title: 'Skaidrojošā vārdnīca',
      show: this.literatureData.ShowGlossary,
      content: this.literatureData.Glossary,
      type: 'glossary',
    });
    this.literatureBlock.push({
      title: 'Dzeja',
      show: this.literatureData.ShowPoems,
      content: this.literatureData.Poems,
      type: 'poems',
    });
    this.literatureBlock.push({
      title: 'Interneta resursu katalogs',
      show: this.literatureData.ShowInternetResourceCatalog,
      content: this.literatureData.InternetResourceCatalog,
      type: 'internetresources',
    });
    this.literatureBlock.push({
      title: 'Darba lapas',
      show: this.literatureData.ShowWorksheets,
      content: this.literatureData.Worksheets,
      type: 'worksheets',
    });
    this.literatureBlock.push({
      title: 'Radošā pašizpausme',
      show: this.literatureData.ShowCreativeSelfExpression,
      content: this.literatureData.CreativeSelfExpression,
      type: 'creativeselfexpression',
    });
  }
}
