<div class="col-md-12 main-info-block">
  <div class="row section">
    <div
      [innerHTML]="this.linksContent | safe: 'html'"
      class="letonika-link"
      target="_blank"
    >
      {{ this.linksContent | safe: "html" }}
    </div>
  </div>
</div>
