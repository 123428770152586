import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-methodology',
  templateUrl: './methodology.component.html',
  styleUrls: ['./methodology.component.scss'],
})
export class MethodologyComponent implements OnInit {
  @ViewChild('object') object!: ElementRef;

  show: boolean = false;
  constructor() {
    setTimeout(() => {
      this.show = true;
    }, 500);
  }
  // constructor(readonly element: ElementRef<HTMLElement>) {}

  // removeAfter!: number;

  ngOnInit() {
    // setTimeout(() => {
    //   this.object.nativeElement.removeAfter
    // }, 200);
  }
}
