import { Component, OnInit } from '@angular/core';
import { HelperBlockContent } from '../../views/methodology/methodology';
import { HelperBlockService } from './helper-block.service';

@Component({
  selector: 'app-helper-block',
  templateUrl: './helper-block.component.html',
  styleUrls: ['./helper-block.component.scss'],
})
export class HelperBlockComponent implements OnInit {
  links!: any;
  linksContent!: any;
  constructor(private helperBlockService: HelperBlockService) {}

  ngOnInit(): void {
    this.helperBlockService
      .getContentForHelperPage()
      .subscribe((response: HelperBlockContent) => {
        this.links = response;
        this.linksContent = this.links.Body[0].HtmlBlock;
      });
  }
}
