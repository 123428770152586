import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import {
  Literature,
  LiteratureBlock,
  LiteratureImage,
  PagesData,
} from '../../literature/literature';
import { LiteratureService } from '../../literature/literature.service';

@Component({
  selector: 'app-assistant-chat',
  templateUrl: './assistant-chat.component.html',
  styleUrls: ['./assistant-chat.component.scss'],
})
export class AssistantChatComponent implements OnInit {
  baseUrl = environment.baseUrl;
  literatureData!: Literature;
  literatureImage!: LiteratureImage;
  literatureBlock: Array<LiteratureBlock> = [];
  toggleViewMoreButton: boolean = false;
  pagesData!: PagesData;
  passedDataType!: string;

  activeRoutParameter!: string;
  constructor(
    private literatureService: LiteratureService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      if (paramMap.get('id') === 'uzmini-zanru-vai-darbu') {
        this.literatureService
          .getPagesData(paramMap.get('id'))
          .subscribe((resp) => {
            this.passedDataType = 'page';
            this.pagesData = resp;
            this.literatureBlock = [];
          });
      } else if (paramMap.get('id') !== null) {
        this.literatureService
          .getLiteratureData(paramMap.get('id'))
          .subscribe((response) => {
            this.passedDataType = 'literature';
            this.literatureData = response;
            this.literatureBlock = [];
            this.populateBlocks(this.literatureData);
          });
      }
    });
  }

  populateBlocks(literatureData: Literature): void {
    this.literatureBlock.push({
      title: 'Skaidrojošā vārdnīca',
      show: this.literatureData.ShowGlossary,
      content: this.literatureData.Glossary,
      type: 'glossary',
    });
    this.literatureBlock.push({
      title: 'Dzeja',
      show: this.literatureData.ShowPoems,
      content: this.literatureData.Poems,
      type: 'poems',
    });
    this.literatureBlock.push({
      title: 'Interneta resursu katalogs',
      show: this.literatureData.ShowInternetResourceCatalog,
      content: this.literatureData.InternetResourceCatalog,
      type: 'internetresources',
    });
    this.literatureBlock.push({
      title: 'Darba lapas',
      show: this.literatureData.ShowWorksheets,
      content: this.literatureData.Worksheets,
      type: 'worksheets',
    });
    this.literatureBlock.push({
      title: 'Radošā pašizpausme',
      show: this.literatureData.ShowCreativeSelfExpression,
      content: this.literatureData.CreativeSelfExpression,
      type: 'creativeselfexpression',
    });
  }
}
