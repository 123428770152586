import { Component, OnInit } from '@angular/core';
import { Worksheets } from '../../views/knowledge/knowledge';

@Component({
  selector: 'app-worksheets-block',
  templateUrl: './worksheets-block.component.html',
  styleUrls: ['./worksheets-block.component.scss'],
})
export class WorksheetsBlockComponent implements OnInit {
  worksheetsBlock: Worksheets[] = [
    {
      title: 'Poēma “Atraitnes dēls”',
      exampleLink: [
        {
          linkLabel: 'Poēmas "Atraitnes dēls" formas teorija',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=pludons#AtraitnesD1',
        },
      ],
      secondExampleLink: [
        {
          linkLabel: 'Poēmas "Atraitnes dēls" refleksija un aktualitāte',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=pludons#AtraitnesD2',
        },
      ],
      thirdExampleLink: [
        {
          linkLabel: 'Poēmas "Atraitnes dēls" satura izpratne',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=pludons#AtraitnesD3',
        },
      ],
      color: '#b6f6ea',
    },
    {
      title: 'Stāstu cikls “Cilvēkam vajag suni”',
      exampleLink: [
        {
          linkLabel: 'Stāstu cikls “Cilvēkam vajag suni”',
          linkUrl: 'https://letonika.lv/kolekcija/?id=macibas&s=ezera#CVSuns',
        },
      ],
      secondExampleLink: [
        {
          linkLabel: 'Stāsta "Gripa" satura izpratne',
          linkUrl: 'https://letonika.lv/kolekcija/?id=macibas&s=ezera#gripa',
        },
      ],
      thirdExampleLink: [
        {
          linkLabel: 'Stāsta "Sāpju mērs" satura izpratne',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=ezera#SapjuMers',
        },
      ],
      color: '#ece7b9',
    },
    {
      title: 'Stāsts “Kauja pie Knipskas”',
      exampleLink: [
        {
          linkLabel: '"Kauja pie Knipskas". Piezīmes lasot stāstu',
          linkUrl: 'https://letonika.lv/kolekcija/?id=macibas&s=poruks#Knipska',
        },
      ],
      secondExampleLink: [
        {
          linkLabel: '"Kauja pie Knipskas". Refleksija un aktualitāte',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=poruks#Knipska2',
        },
      ],
      thirdExampleLink: [
        {
          linkLabel: '"Kauja pie Knipskas". Darbs ar tekstu kā mākslas darbu',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=poruks#Knipska3',
        },
      ],
      color: '#c6d2f7',
    },
    {
      title: 'Eposs “Lāčplēsis',
      exampleLink: [
        {
          linkLabel: 'Lāčplēsis. Darbs ar tekstu: 1.-2. dziedājums',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=pumpurs#lacplesis1',
        },
      ],
      secondExampleLink: [
        {
          linkLabel: 'Lāčplēsis. Darbs ar tekstu: 3. dziedājums',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=pumpurs#lacplesis3',
        },
      ],
      thirdExampleLink: [
        {
          linkLabel: 'Lāčplēsis. Darbs ar tekstu: 4.-6. dziedājums',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=pumpurs#lacplesis3',
        },
      ],
      fourthExampleLink: [
        {
          linkLabel: 'Refleksija par A.Pumpura eposu “Lāčplēsis”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=pumpurs#Refleksija',
        },
      ],
      color: '#e3f1f9',
    },
    {
      title: 'Literārā pasaka “Kā es braucu Ziemeļmeitas lūkoties”',
      exampleLink: [
        {
          linkLabel: '"Kā es braucu Ziemeļmeitas lūkoties" teksta izpratne',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=skalbe#Zieme%C4%BCmeita',
        },
      ],
      secondExampleLink: [
        {
          linkLabel:
            '"Kā es braucu Ziemeļmeitas lūkoties" tēla attīstība no folkloras līdz mūsdienām',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=skalbe#Zieme%C4%BCmeita2',
        },
      ],
      thirdExampleLink: [
        {
          linkLabel:
            '"Kā es braucu Ziemeļmeitas lūkoties" pasakas žanra iezīmes un teksta tēlainība',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=skalbe#Zieme%C4%BCmeita3',
        },
      ],
      color: '#f0d2d2',
    },
    {
      title: 'Novele “Nāves ēnā”',
      exampleLink: [
        {
          linkLabel: 'Noveles "Salna pavasarī", "Nāves ēnā", "Purva bridējs"',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=blaumanis#Rb3noveles',
        },
      ],
      secondExampleLink: [
        {
          linkLabel: 'Uzdevumu kopa par noveli "Nāves ēnā"',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=blaumanis#NavesEna',
        },
      ],
      color: '#e7e7e7',
    },
    {
      title: 'Luga “Skroderdienas Silmačos”',
      exampleLink: [
        {
          linkLabel: '„Skroderdienas Silmačos”, uzdevumu kopa',
          linkUrl: '',
        },
      ],
      color: '#e2d5e4',
    },
    {
      title: 'Z.Mauriņas esejas',
      exampleLink: [
        {
          linkLabel: 'Ieskats Zentas Mauriņas biogrāfijā un daiļradē',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=maurina#persona',
        },
      ],
      secondExampleLink: [
        {
          linkLabel: 'Zenta Mauriņa. Esejas',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=maurina#Mauri%C5%86aEsejas',
        },
      ],
      color: '#d0e4ed',
    },
    {
      title: 'O.Vācieša dzeja',
      exampleLink: [
        {
          linkLabel: 'Ojāra Vācieša dzejas interpretācija',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=vacietis#atmoda',
        },
      ],
      secondExampleLink: [
        {
          linkLabel: 'Ojārs Vācietis "Dzeja ir vārdiska mūzika"',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=vacietis#OVdzeja',
        },
      ],
      color: '#eafbd0',
    },
    {
      title: 'Epifānijas',
      exampleLink: [
        {
          linkLabel:
            'Jēdziena "rāmis" izpratne un "rāmja tēls" Imanta Ziedoņa epifānijās',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=ziedonis#Jedziens',
        },
      ],
      secondExampleLink: [
        {
          linkLabel: 'Imanta Ziedoņa epifānija “Apiet apkārt”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=ziedonis#ApietApkart',
        },
      ],
      thirdExampleLink: [
        {
          linkLabel: 'Imanta Ziedoņa epifānija “Ir viens vārds”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=ziedonis#ApietApkart',
        },
      ],
      fourthExampleLink: [
        {
          linkLabel:
            'Imanta Ziedoņa epifānija “Visi mēs esam pudeles un kārbiņas”',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=ziedonis#PudelesKarbinas',
        },
      ],
      color: '#e6e9fa',
    },
    {
      title: 'Mākslinieciskās izteiksmes līdzekļi',
      exampleLink: [
        {
          linkLabel: 'Valodas līdzekļi teksta izteiksmīguma radīšanai',
          linkUrl:
            'https://letonika.lv/kolekcija/?id=macibas&s=teorija#ValodasLidzeklji',
        },
      ],
      color: '#cdd3d9',
    },
    {
      title: 'Literatūras veidi un žanri',
      exampleLink: [
        {
          linkLabel: 'Literatūras žanru pārskats',
          linkUrl: 'https://letonika.lv/kolekcija/?id=macibas&s=teorija#zanri',
        },
      ],
      secondExampleLink: [
        {
          linkLabel: 'Atpazīsti literatūras žanrus',
          linkUrl: 'https://letonika.lv/kolekcija/?id=macibas&s=teorija#zanri2',
        },
      ],
      color: '#cbddda',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
