<div
  class="about-content"
  [ngClass]="componentColor"
  [class.with-assistant]="assistant"
  [class.with-pages-data]="pagesData"
>
  <ng-container *ngIf="type !== 'page'">
    <div class="name-quote" [class.with-assistant]="assistant">
      <div class="row">
        <div class="col-md-5">
          <div class="row work-title" [class.with-assistant]="assistant">
            {{ literatureData.Title }}
          </div>
        </div>
        <div
          class="col-md-7 quote d-flex align-items-end flex-column"
          [class.with-assistant]="assistant"
          [innerHTML]="literatureData.TitleQuote | safe: 'html'"
        >
          {{ literatureData.TitleQuote }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'page'">
    <div class="row top-section">
      <div class="col-5 guess-the-genre-title">
        <div class="row">
          <h1>Uzmini žanru vai darbu!</h1>
        </div>
      </div>
      <div class="col-7 game-title">
        <div class="row">
          <h1>{{ pagesData.Title }}</h1>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="book-chat">
    <div
      *ngIf="assistant"
      class="content col-4 webchat-container"
      [class.with-assistant]="assistant"
    >
      <div class="green sidebar-chat" #botWindow></div>
    </div>

    <object
      *ngIf="assistant"
      type="image/svg+xml"
      [data]="changeChatAnimation() | safe: 'resourceUrl'"
      class="animation"
      [class.with-assistant]="assistant"
    ></object>

    <ng-container *ngIf="type === 'page'">
      <div class="content" [class.with-pages-data]="pagesData">
        <h6 [innerHTML]="pagesData.Body[0].HtmlBlock | safe: 'html'">
          {{ pagesData.Body[0].HtmlBlock | safe: "html" }}
        </h6>
      </div>
    </ng-container>

    <ng-container *ngIf="type !== 'page'">
      <div
        class="content row block"
        [class.col-12]="!assistant"
        [class.with-assistant]="assistant"
      >
        <div
          class="col-6 detailed-info"
          [class.col-8]="assistant"
          [class.col-12]="!assistant"
          [class.with-assistant]="assistant"
        >
          <div class="row specific-info-about-work">
            <div class="col title-col">
              <div class="row title">Autors:</div>
            </div>
            <div class="col">
              <div class="row b-e-data">{{ literatureData.Author }}</div>
              <a
                class="row view-link"
                [ngClass]="componentColor"
                href="{{ literatureData.AuthorBiographyURL }}"
                target="_blank"
                >skatīt autora biogrāfiju</a
              >
            </div>
          </div>

          <div class="row specific-info-about-work">
            <div class="col title-col">
              <div class="row title">Pirmpublicējums:</div>
            </div>
            <div class="col">
              <div class="row b-e-data">
                {{ literatureData.FirstPublished }}
              </div>
            </div>
          </div>

          <div class="row specific-info-about-work">
            <div class="col title-col">
              <div class="row title">Literatūras veids:</div>
            </div>
            <div class="col">
              <div class="row b-e-data">
                {{ literatureData.LiteratureType }}
              </div>

              <a
                class="row view-link"
                [routerLink]="['/literaturas-veidi-un-zanri']"
                target="_blank"
                [ngClass]="componentColor"
                >skatīt literatūras veidu un žanru karti</a
              >
            </div>
          </div>

          <div class="row specific-info-about-work">
            <div class="col title-col">
              <div class="row title">Literatūras žanrs:</div>
            </div>
            <div class="col">
              <div class="row b-e-data">
                {{ literatureData.LiteratureGenre }}
              </div>
            </div>
          </div>

          <div class="row specific-info-about-work">
            <div class="col title-col">
              <div class="row title">Literatūras virziens:</div>
            </div>
            <div class="col">
              <div class="row b-e-data">
                {{ literatureData.LiteratureDirection }}
              </div>

              <a
                [routerLink]="['/laika-karte']"
                class="row view-link"
                target="_blank"
                [ngClass]="componentColor"
              >
                skatīt literatūras virzienu laika karti</a
              >
            </div>
          </div>
        </div>
        <div
          class="col-3 literature-image-section"
          [class.col-4]="assistant"
          [class.col-6]="!assistant"
          [class.with-assistant]="assistant"
        >
          <img
            class="literature-cover"
            [src]="baseUrl + literatureData.Image.url"
          />
        </div>

        <!-- ------------------------------ -->

        <div
          class="col-3 explore-more"
          [class.col-6]="assistant"
          [class.col-3]="!assistant"
          [class.with-assistant]="assistant"
        >
          <div
            class="col title needs-to-be-flexed"
            *ngIf="literatureData.ShowReadLink"
          >
            <div class="row read-label">{{ literatureData.ReadLabel }}</div>
            <div class="row read-url">
              <a
                href="{{ literatureData.ReadURL }}"
                class="row direct-to-other-sources"
              >
                <span>letonika.lv</span> Lasītavā
              </a>
            </div>
          </div>
          <div
            class="row poem-list title"
            *ngIf="literatureData.ShowPoemList"
            [innerHTML]="literatureData.PoemList | safe: 'html'"
          >
            {{ literatureData.PoemList | safe: "html" }}
          </div>
          <div
            class="col needs-to-be-flexed"
            [class.with-assistant]="assistant"
          >
            <div class="row title" *ngIf="literatureData.ShowListenLink">
              <div class="row listen-label">
                {{ literatureData.ListenLabel }}
              </div>
              <div class="row listen-url">
                <a
                  href="{{ literatureData.ListenURL }}"
                  class="row direct-to-other-sources listen"
                >
                  <span>letonika.lv</span> Audiolasītavā</a
                >
              </div>

              <!-- teksts Audiolasītavā ir tikai tad, ja links ir uz letoniku. Ja ir yt links, nekāda papildus teksta nav -->
              <!-- <div class="row">{{ literatureData.ListenURL }} Audiolasītavā</div> -->
            </div>
          </div>
        </div>
        <div
          class="col-10 assistant-learn-more"
          [class.with-assistant]="assistant"
        >
          <div class="row assistant-lower-text">
            <div class="col-1">
              <img src="assets/images/arrow-left.svg" alt="" />
            </div>
            <div class="col">
              <h1
                [innerHTML]="literatureData.AssistantLowerText | safe: 'html'"
              >
                {{ literatureData.AssistantLowerText | safe: "html" }}
              </h1>
            </div>
          </div>

          <br />
          <div class="col find-out-more">
            <div class="col-1">
              <h1>Izzini vairāk</h1>

              <img src="assets/images/arrow-down.svg" alt="" />
            </div>
            <div class="col-1"></div>
          </div>
        </div>
        <!-- ---------------------------- -->
      </div>
    </ng-container>
  </div>
</div>
