import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VirtualAssistantText } from './virtual-assistant';

@Injectable({
  providedIn: 'root',
})
export class VirtualAssistantService {
  private url = 'https://dmml-strapi-dev.azurewebsites.net/pages/va-kas-un-ka';
  private urlSecond =
    'https://dmml-strapi-dev.azurewebsites.net/pages/izveido-sava-skola';
  constructor(private http: HttpClient) {}

  getVirtualAssistantFirstText() {
    return this.http.get<VirtualAssistantText>(this.url);
  }

  getVirtualAssistantSecondText() {
    return this.http.get<VirtualAssistantText>(this.urlSecond);
  }
}
