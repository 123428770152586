<header>
  <nav>
    <ul class="nav-links" [class.nav-active]="mobileNavbarOpen">
      <li>
        <a [routerLink]="['']"
          ><img class="manual" src="assets/images/manual.png" alt=""
        /></a>
      </li>
      <li class="homepage-mobile">
        <div class="dropdown">
          <button class="dropbtn">
            <a [routerLink]="['']" (click)="navSlide()">Sākumlapa</a>
          </button>
        </div>
      </li>
      <li>
        <div class="dropdown">
          <button class="dropbtn literature">Literatūras darbi</button>
          <div class="dropdown-content">
            <div
              class="
                row row-cols-1 row-cols-2
                card-grid
                pt-5
                mobile-cover-image-list
              "
            >
              <div
                class="links"
                *ngFor="let item of articlesData; let i = index"
              >
                <ul class="mobile-cover-list">
                  <a
                    routerLink="{{ bookListLinkArray[i].link }}"
                    routerLinkActive="router-link-active"
                    (click)="navSlide()"
                  >
                    <img
                      src="{{ bookListLinkArray[i].imageUrl }}"
                      alt=""
                      class="mobile-cover-image"
                    />
                    <h6>{{ item.Title }}</h6>
                    <h6>{{ item.Author }}</h6>
                  </a>
                </ul>
              </div>
            </div>
            <div class="book-list-names">
              <a
                [routerLink]="['/literatura/atraitnes-dels']"
                routerLinkActive="active"
                >Atraitnes dēls</a
              >

              <a
                [routerLink]="['/literatura/cilvekam-vajag-suni']"
                routerLinkActive="active"
                >Cilvēkam vajag suni</a
              >
              <a
                [routerLink]="['/virtualie-asistenti/kauja-pie-knipskas']"
                routerLinkActive="active"
                >Kauja pie Knipskas</a
              >
              <a
                [routerLink]="['/literatura/lacplesis']"
                routerLinkActive="active"
                >Lāčplēsis</a
              >
              <a
                [routerLink]="[
                  '/literatura/ka-es-braucu-ziemelmeitas-lukoties'
                ]"
                routerLinkActive="active"
                >Kā es braucu Ziemeļmeitas lūkoties</a
              >
              <a
                [routerLink]="['/virtualie-asistenti/naves-ena']"
                routerLinkActive="active"
                >Nāves ēnā</a
              >
              <a
                [routerLink]="['/virtualie-asistenti/skroderdienas-silmacos']"
                routerLinkActive="active"
                >Skroderdienas Silmačos</a
              >
              <a
                [routerLink]="['/literatura/zentas-maurinas-esejas']"
                routerLinkActive="active"
                >Z.Mauriņas esejas</a
              >
              <a
                [routerLink]="['/literatura/ojara-vaciesa-dzeja']"
                routerLinkActive="active"
                >O.Vācieša dzeja</a
              >
              <a
                [routerLink]="['/literatura/epifanijas']"
                routerLinkActive="active"
                >Epifānijas</a
              >
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="dropdown">
          <button class="dropbtn learn">Mācies interaktīvi</button>
          <div class="dropdown-content">
            <a
              [routerLink]="['/makslinieciskas-izteiksmes-lidzekli']"
              routerLinkActive="active"
              (click)="navSlide()"
              >Mākslinieciskās izteiksmes līdzekļi</a
            >
            <a
              [routerLink]="['/literaturas-veidi-un-zanri']"
              routerLinkActive="active"
              (click)="navSlide()"
              >Literatūras veidi un žanri</a
            >
            <a
              [routerLink]="['/laika-karte']"
              routerLinkActive="active"
              (click)="navSlide()"
              >Laika karte</a
            >
            <a
              [routerLink]="['/darba-lapas']"
              routerLinkActive="active"
              (click)="navSlide()"
              >Darba lapas</a
            >
            <a
              [routerLink]="['/riki-radosumam']"
              routerLinkActive="active"
              (click)="navSlide()"
              >Rīki radošumam</a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="dropdown">
          <button class="dropbtn assistants">Virtuālie asistenti</button>
          <div class="dropdown-content">
            <a
              routerLink="/virtualie-asistenti"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="navSlide()"
              >Kas ir virtuālais asistents?</a
            >
            <a
              routerLink="/virtualie-asistenti/kauja-pie-knipskas"
              routerLinkActive="active"
              (click)="navSlide()"
              >Cibiņš</a
            >
            <a
              routerLink="/virtualie-asistenti/skroderdienas-silmacos"
              routerLinkActive="active"
              (click)="navSlide()"
              >Skroderiene</a
            >
            <a
              routerLink="/virtualie-asistenti/naves-ena"
              routerLinkActive="active"
              (click)="navSlide()"
              >Zvejnieks</a
            >
            <a
              routerLink="/virtualie-asistenti/uzmini-zanru-vai-darbu"
              routerLinkActive="active"
              (click)="navSlide()"
              >Uzmini žanru vai darbu!</a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="dropdown">
          <button class="dropbtn">Pedagogiem</button>
          <div class="dropdown-content teachers">
            <a
              routerLink="/pedagogiem/metodologija"
              routerLinkActive="active"
              (click)="navSlide()"
              >Metodoloģija</a
            >
            <a
              routerLink="/pedagogiem/paligs"
              routerLinkActive="active"
              (click)="navSlide()"
              >Palīgs</a
            >
            <a
              routerLink="/pedagogiem/par-projektu"
              routerLinkActive="active"
              (click)="navSlide()"
              >Par projektu</a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="dropbtn dropdown">
          <a
            href="https://letonika.lv/default.aspx"
            class="letonika-link"
            target="_blank"
            >letonika.lv</a
          >
        </div>
      </li>
      <!-- <li>
        <div class="dropdown">
          <button class="login-btn pvw-title">
            <span>
              <img
                class="loginicon"
                src="assets/images/access-key-outline-icon.png"
                alt=""
              />
              IENĀKT</span
            >
          </button>
        </div>
      </li> -->
    </ul>
    <div class="burger" (click)="navSlide()" [class.toggle]="mobileNavbarOpen">
      <div class="line1"></div>
      <div class="line2"></div>
      <div class="line3"></div>
    </div>
  </nav>
  <!-- <div class="row row-cols-1 row-cols-2 card-grid pt-5 mobile-cover-image-list">
    <ul *ngFor="let articleData of articlesData" class="mobile-cover-list">
      <img
        [src]="baseUrl + articleData.Image.url"
        alt=""
        class="mobile-cover-image"
      />
      <h6>
        {{ articleData.Title }}
      </h6>
    </ul>
  </div> -->
</header>
