<div class="row pt-5" *ngIf="show">
  <div
    class="row container-section-info"
    [class.glossary]="type === 'glossary'"
  >
    <div class="row title">{{ title }}</div>
    <div>
      <div
        class="row content-padding"
        [class.show-content]="toggleViewMoreButton"
        [ngClass]="[
          type !== 'worksheets' && type !== 'creativeselfexpression'
            ? 'hide-content'
            : 'content'
        ]"
      >
        <div class="row second-content" [innerHTML]="content | safe: 'html'">
          {{ content | safe: "html" }}
        </div>
        <div class="row mobile-bottom" *ngIf="type === 'worksheets'">
          <a
            href="https://letonika.lv/darbalapas"
            class="view-link"
            target="_blank"
            >Atvērt letonika.lv darba lapu sadaļu</a
          >
        </div>
        <div class="row bottom" *ngIf="type === 'creativeselfexpression'">
          <a
            [routerLink]="['/riki-radosumam']"
            class="view-link"
            target="_blank"
            >Atvērt DMML digitālo rīku sadaļu</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- show more block -->
  <div
    class="row shadow-underline"
    *ngIf="type !== 'worksheets' && type !== 'creativeselfexpression'"
  >
    <button (click)="toggleViewMore()" class="view-more-btn show-more">
      Rādīt {{ toggleViewMoreButton ? " mazāk" : " vairāk" }}
      <img
        src="assets/images/arrow.png"
        alt=""
        class="view-more-button-icon"
        [class.view-more-button-icon-toggled]="toggleViewMoreButton"
      />
    </button>
  </div>
  <!-- end of show more block -->
</div>
