import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { ArticlesData, BookList } from '../../views/literature/literature';
import { LiteratureService } from '../../views/literature/literature.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  baseUrl = environment.baseUrl;
  articlesData!: Array<ArticlesData>;
  mobileNavbarOpen: boolean = false;
  isMobile = this.deviceService.isMobile();
  isTablet = this.deviceService.isTablet();

  bookListLinkArray: BookList[] = [
    {
      link: '/literatura/atraitnes-dels',
      imageUrl: 'assets/images/atraitnes_dels_bookcover.png',
    },
    {
      link: '/virtualie-asistenti/naves-ena',
      imageUrl: 'assets/images/naves_ena_bookcover.png',
    },
    {
      link: '/virtualie-asistenti/kauja-pie-knipskas',
      imageUrl: 'assets/images/kauja_pie_knipskas_bookcover.png',
    },
    {
      link: '/literatura/cilvekam-vajag-suni',
      imageUrl: 'assets/images/cilvekam_vajag_suni_bookcover.png',
    },
    {
      link: '/literatura/epifanijas',
      imageUrl: 'assets/images/epifanijas_bookcover.png',
    },
    {
      link: '/literatura/lacplesis',
      imageUrl: 'assets/images/lacplesis_bookcover.png',
    },
    {
      link: '/literatura/ka-es-braucu-ziemelmeitas-lukoties',
      imageUrl:
        'assets/images/ka_es_braucu_ziemelmeitas_lukoties_bookcover.png',
    },
    {
      link: '/virtualie-asistenti/skroderdienas-silmacos',
      imageUrl: 'assets/images/skroderdienas_silmacos_bookcover.png',
    },
    {
      link: '/literatura/zentas-maurinas-esejas',
      imageUrl: 'assets/images/zentas_maurinas_esejas_bookcover.png',
    },
    {
      link: '/literatura/ojara-vaciesa-dzeja',
      imageUrl: 'assets/images/ojars_vacietis_bookcover.png',
    },
  ];
  constructor(
    private literatureService: LiteratureService,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.isMobile || this.isTablet
      ? this.literatureService
          .getAllArticlesData()
          .subscribe(
            (articlesResponse) => (this.articlesData = articlesResponse)
          )
      : '';
  }

  navSlide() {
    this.isMobile || this.isTablet
      ? (this.mobileNavbarOpen = !this.mobileNavbarOpen)
      : '';
  }
}
