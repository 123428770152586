import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ScriptService } from 'src/app/scripts-store/script.service';
import { ArtisticExpressionBlock } from '../../views/knowledge/knowledge';

declare var vis: any;

@Component({
  selector: 'app-artistic-expression-block',
  templateUrl: './artistic-expression-block.component.html',
  styleUrls: ['./artistic-expression-block.component.scss'],
  providers: [ScriptService],
})
export class ArtisticExpressionBlockComponent implements OnInit {
  @ViewChild('network') networkContainer!: ElementRef;
  @ViewChildren('target')
  target!: ElementRef;
  private network: any;

  artisticExpressionBlock: ArtisticExpressionBlock[] = [
    {
      artisticExpressionTitle: 'Valodas un tēlainības līdzekļi',
      artisticExpressionDefinition:
        'Valodas un mākslinieciskās izteiksmes līdzekļi veido literārā teksta valodas savdabību, tēlainību un izteiksmīgumu.',
      firstExample:
        'Valodas stilistiskajiem un mākslinieciskās (jeb tēlainās) izteiksmes līdzekļiem un to interpretācijai (skaidrojumam) ir būtiska nozīme literārā darba satura atklāsmē. Šos līdzekļus tradicionāli iedala tropos  (sauktos arī par mākslinieciskās izteiksmes līdzekļiem) un stilistiskajās figūrās.',
      firstExampleAuthor: null,
      firstExampleLink: null,
      secondExample: null,
      secondExampleAuthor: null,
      secondExampleLink: null,
      encyclopediaLink: null,
      letonikaLink: null,
      readMoreText: null,
      isMainArticle: true,
      shouldBeDisplayedAsExample: true,
    },
    {
      artisticExpressionTitle: 'Tropi',
      artisticExpressionDefinition:
        'Tropi jeb mākslinieciskās izteiksmes līdzekļi ir vārdi, vārdu savienojumi vai izteicieni, kas lietoti ne to tiešajā, bet pārnestajā nozīmē.',
      firstExample:
        'Trops tiek definēts kā "verborum immutatio" – vārdu maiņa. Sengrieķu filozofs Aristotelis uzsvēra, ka tropi nav kaut kas haotiski nepiemērots, bet gan tēlainība ar semantisku saikni. Kāds nezināms latviešu dziesminieks ir radījis poētisku terminu “aplokaina valoda”, domādams ar to tādu izteiksmes veidu, kad notikumu norise un domu saturs neietveras vārdu un teikumu tiešajā nozīmē, bet uzminams vai nu kā secinājums no teiktā vai arī kā vārdu un teikumu simbolikā visiem dzirdams, bet tikai vērīgiem saprotams noslēpums.',
      firstExampleAuthor: null,
      firstExampleLink: null,
      secondExample: null,
      secondExampleAuthor: null,
      secondExampleLink: null,
      encyclopediaLink:
        'https://enciklopedija.lv/skirklis/28735-tropi,-literat%C5%ABr%C4%81',
      letonikaLink:
        'https://letonika.lv/groups/default.aspx?cid=33046&r=4&lid=33046',
      readMoreText: 'tropiem',
      shouldBeDisplayedAsExample: true,
    },
    {
      artisticExpressionTitle: 'Epitets',
      artisticExpressionDefinition:
        'Epitets – sevišķi spilgts un svarīgs apzīmējums.',
      firstExample:
        'Ceļinieki nogriezās no <span style="color:#DD0000;">baltā</span> lielceļa pa <span style="color:#DD0000;">tumšām</span> vītolu un kļavu gatvēm [..]',
      firstExampleAuthor: '/K.Skalbe "Kā es braucu Ziemeļmeitas lūkoties"/',
      firstExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=290&q=balt%C4%81%20tum%C5%A1%C4%81m#3110870/I:1/P:21',
      secondExample:
        'Jaunekle īsta skaistule bija,<br /> Tumšbrūnām, <span style="color:#DD0000;">zvēroti dedzīgām</span> acīm.',
      secondExampleAuthor: '/A.Pumpurs "Lāčplēsis"/',
      secondExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=163&q=%22zv%C4%93roti%20dedz%C4%ABg%C4%81m%22#2190811/I:3/P:28',
      encyclopediaLink: 'https://enciklopedija.lv/skirklis/1991-epitets,-trops',
      letonikaLink:
        'https://letonika.lv/groups/default.aspx?r=4&q=epitets&id=967545&g=1',
      readMoreText: 'epitetu',
    },
    {
      artisticExpressionTitle: 'Salīdzinājums',
      artisticExpressionDefinition:
        'Salīdzinājums – divu līdzīgu priekšstatu saistījums.',
      firstExample:
        'Visās malās plūda <span style="color:#DD0000;">krēsla kā</span> klusi tumšpelēki <span style="color:#DD0000;">ūdeņi</span>.',
      firstExampleAuthor: '/K.Skalbe "Kā es braucu Ziemeļmeitas lūkoties"/',
      firstExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=290&q=%22kr%C4%93sla%20k%C4%81%22#3110870/I:1/P:19',
      secondExample:
        'Cibiņa iekritušās acis bija atdzīvojušās, kuru urbjošais <span style="color:#DD0000;">skats</span> Buņģim <span style="color:#DD0000;">kā asmens</span> sirdī griezās.',
      secondExampleAuthor: '/J.Poruks "Kauja pie Knipskas"/',
      secondExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=154&q=%22k%C4%81%20asmens%22#2191034/I:4/P:184',
      encyclopediaLink:
        'https://enciklopedija.lv/skirklis/1995-sal%C4%ABdzin%C4%81jums,-trops',
      letonikaLink:
        'https://letonika.lv/groups/default.aspx?r=4&q=sal%C4%ABdzin%C4%81jums&id=968072&g=1',
      readMoreText: 'salīdzinājumu',
    },
    {
      artisticExpressionTitle: 'Metafora',
      artisticExpressionDefinition:
        'Metafora – vārds, kas lietots cita vārda vietā uz priekšstatu vai nozīmju līdzības pamata.',
      firstExample:
        '<span style="color:#DD0000;">Sāpes</span> vairs <span style="color:#DD0000;">nedzēla</span>.',
      firstExampleAuthor: '/Z.Mauriņa "Dziedinātājs klusums"/',
      firstExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=383&q=%22S%C4%81pes%20vairs%20nedz%C4%93la%22#3720704/I:27/P:186',
      secondExample:
        '<span style="color:#DD0000;">Debesu skaistās acis</span> bija cieši aiztītas.',
      secondExampleAuthor: '/K.Skalbe "Kā es braucu Ziemeļmeitas lūkoties"/',
      secondExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=290&q=%22Debesu%20skaist%C4%81s%20acis%22#3110870/I:1/P:49',
      encyclopediaLink:
        'https://enciklopedija.lv/skirklis/1759-metafora,-trops',
      letonikaLink:
        'https://letonika.lv/groups/default.aspx?r=4&q=metafora&id=967817&g=1',
      readMoreText: 'metaforu',
    },
    {
      artisticExpressionTitle: 'Personifikācija',
      artisticExpressionDefinition:
        'Personifikācija – cilvēka īpašību vai spēju piedēvējums priekšmetiem, dzīvām būtnēm vai parādībām.',
      firstExample:
        '<span style="color:#DD0000;">Klusums salasa</span> izkaisītos <span style="color:#DD0000;">spēkus, atver vārtus</span> uz lietu būtību.',
      firstExampleAuthor: '/Z.Mauriņa "Dziedinātājs klusums"/',
      firstExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=383&q=%22klusums%20salasa%22#3720704/I:27/P:162',
      secondExample:
        '<span style="color:#DD0000;">Saule gāja</span> pār kalnu, un jauns puika bez cepures, basām kājām kūleņoja viņai līdz pa kukuržņiem un velēnām, pats nezinādams, kur kājas liek.',
      secondExampleAuthor: '/K.Skalbe "Kā es braucu Ziemeļmeitas lūkoties"/',
      secondExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=290&q=%22Saule%20g%C4%81ja%22#3110870/I:1/P:125',
      encyclopediaLink:
        'https://enciklopedija.lv/skirklis/3157-personifik%C4%81cija,-trops',
      letonikaLink:
        'https://letonika.lv/groups/default.aspx?r=4&q=personifik%C4%81cija&id=967958&g=1',
      readMoreText: 'personifikāciju',
    },
    {
      artisticExpressionTitle: 'Alegorija',
      artisticExpressionDefinition:
        'Alegorija – atpazīstama, pārnestas nozīmes tēla lietojums cita tēla vietā.',
      firstExample:
        'Cik <span style="color:#DD0000;">zemas</span>, cik <span style="color:#DD0000;">zemas</span> bij viņu <span style="color:#DD0000;">debesis</span>! Lūk, tur pie viņām bija žāvēšanai izkārta teļādiņa un izpūsts cūkas pūslis; pret katru ēdēju bija debesīs iesprausta viņa karote, un taisni pār manu galvu karājās bezmēns…',
      firstExampleAuthor: '/K.Skalbe "Kā es braucu Ziemeļmeitas lūkoties"/',
      firstExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=290&q=zemas%20debesis#3110870/I:1/P:77',
      secondExample:
        'Neskriet taisni jūrā, <span style="color:#DD0000;">nebrist ziedā, nekāpt cita dvēselē</span>, bet iet tuvu līdzās, apiet apkārt, palikt tuvu.',
      secondExampleAuthor: '/I.Ziedonis "Apiet apkārt"/',
      secondExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=251&q=nebrist%20zied%C4%81,%20nek%C4%81pt%20cita%20dv%C4%93sel%C4%93#2676408/I:2/P:24',
      encyclopediaLink:
        'https://enciklopedija.lv/skirklis/8864-alegorija,-trops',
      letonikaLink:
        'https://letonika.lv/groups/default.aspx?r=4&q=alegorija&id=967493&g=1',
      readMoreText: 'alegoriju',
    },
    {
      artisticExpressionTitle: 'Simbols',
      artisticExpressionDefinition:
        'Simbols – vienots, daudznozīmīgs tēls, kas nosacīti pauž ideju.',
      firstExample:
        'Klusumā mūsos iestrāvo <span style="color:#DD0000;">mūžīgā gaisma</span>.',
      firstExampleAuthor: '/Z.Mauriņa "Dziedinātājs klusums"/',
      firstExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=383&q=%22m%C5%AB%C5%BE%C4%ABg%C4%81%20gaisma%22#3720704/I:27/P:156',
      secondExample:
        'Es kāpt gribu <span style="color:#DD0000;">kalnā</span> visaugstākā, <br /> Ne spēka man pietrūks, ne bail man no kā; <br /> Lai putenis plosās, lai ziemelis elš; <br /> Uz augšu, uz augšu tik ies manim ceļš!',
      secondExampleAuthor: '/V.Plūdonis "Atraitnes dēls"/',
      secondExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=348&q=%22kaln%C4%81%22#3338474/I:1/P:59',
      encyclopediaLink:
        'https://enciklopedija.lv/skirklis/27919-simbols,-trops',
      letonikaLink:
        'https://letonika.lv/groups/default.aspx?r=4&q=simbols&id=968110&g=1',
      readMoreText: 'simbolu',
    },
    {
      artisticExpressionTitle: 'Stilistiskās figūras',
      artisticExpressionDefinition:
        'Stilistiskās figūras ir vārdi, vārdu savienojumi vai izteicieni, kas lietoti tēlainības un izteiksmības veidošanai.',
      firstExample:
        'Stilistisko figūru funkcija ir veidot tēlainu un greznotu izteiksmi, kas nav sarunvalodas norma, lai gan ir arī tādas figūras, kas vērojamas ikdienas runā. Lai gan stilistiskās figūras sevišķi daudz parādās dzejā, tās ir vērojamas arī prozā, tautasdziesmās, runās, reklāmās, filmās, kā arī sarunvalodā. Stilistisko figūru lietojums īpaši spilgts ir klasicismā – laikā no 17.–19. gs., kad par paraugu visam tiek pieņemta antīkās pasaules kultūra. Šajā periodā daiļdarba vērtība tiek noteikta pēc tropu un stilistisko figūru lietojuma biežuma. Vēlāk modernismā un postmodernismā tropi un stilistiskās figūras netiek lietotas tik bieži un mērķtiecīgi.',
      firstExampleAuthor: null,
      firstExampleLink: null,
      secondExample: null,
      secondExampleAuthor: null,
      secondExampleLink: null,
      encyclopediaLink: 'https://enciklopedija.lv/skirklis/38022',
      letonikaLink: 'https://letonika.lv/groups/default.aspx?cid=32378',
      readMoreText: 'stiistiskajām figūrām',
      shouldBeDisplayedAsExample: true,
      isMainStylisticFigure: true,
    },
    {
      artisticExpressionTitle: 'Asonanse',
      artisticExpressionDefinition:
        'Asonanse – vienādu patskaņu mērķtiecīgs atkārtojums vairākos vārdos.',
      firstExample:
        'V<span style="color:#DD0000;">a</span>r<span style="color:#DD0000;">a</span> r<span style="color:#DD0000;">a</span>ti, <br /> G<span style="color:#DD0000;">a</span>ri, pl<span style="color:#DD0000;">a</span>ti, <br /> R<span style="color:#DD0000;">a</span>tu r<span style="color:#DD0000;">a</span>tiem g<span style="color:#DD0000;">a</span>rām trauc [..]',
      firstExampleAuthor: '/V.Plūdonis "Atraitnes dēls"/',
      firstExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=348&q=%22Vara%20rati%22#3338474/I:1/P:78',
      secondExample:
        'Starp vis<span style="color:#DD0000;">ā</span>m Jeņisej<span style="color:#DD0000;">ā</span>m, <br /> Starp vis<span style="color:#DD0000;">ā</span>m Gang<span style="color:#DD0000;">ā</span>m un Elb<span style="color:#DD0000;">ā</span>m, <br /> Pie visbalt<span style="color:#DD0000;">ā</span>k<span style="color:#DD0000;">ā</span>m dzej<span style="color:#DD0000;">ā</span>m <br /> Un pie vismeln<span style="color:#DD0000;">ā</span>k<span style="color:#DD0000;">ā</span> nelab<span style="color:#DD0000;">ā</span>. <br />',
      secondExampleAuthor: '/O.Vācietis "Gauja"/',
      secondExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=306&q=%22Starp%20vis%C4%81m%20Je%C5%86isej%C4%81m%22#3111112/I:1/P:35',
      encyclopediaLink:
        'https://enciklopedija.lv/skirklis/35603-asonanse,-stilistiska-fig%C5%Abra',
      letonikaLink:
        'https://letonika.lv/groups/default.aspx?r=4&q=asonanse&id=968114&g=1',
      readMoreText: 'asonansi',
      isStylisticFigure: true,
    },
    {
      artisticExpressionTitle: 'Aliterācija',
      artisticExpressionDefinition:
        'Aliterācija – vienādu līdzskaņu mērķtiecīgs atkārtojums vairākos vārdos.',
      firstExample:
        '<span style="color:#DD0000;">R</span>umak, <span style="color:#DD0000;">r</span>auji, <span style="color:#DD0000;">r</span>atu <span style="color:#DD0000;">r</span>inda, <span style="color:#DD0000;">r</span>iti!',
      firstExampleAuthor: '/V.Plūdonis "Atraitnes dēls"/',
      firstExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=348&q=%22Rumak,%20rauji,%20ratu%20rinda,%20riti%22#3338474/I:1/P:28',
      secondExample:
        'Bet tagad <span style="color:#DD0000;">l</span>īst <span style="color:#DD0000;">l</span>ietus. <br /> <span style="color:#DD0000;">L</span>īst dzeltenīgs <span style="color:#DD0000;">l</span>iep<span style="color:#DD0000;">l</span>apu <span style="color:#DD0000;">l</span>ietus.',
      secondExampleAuthor: '/O.Vācietis "Dziesma"/',
      secondExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=306&q=%22Bet%20tagad%20l%C4%ABst%20lietus%22#3111112/I:1/P:121',
      encyclopediaLink:
        'https://enciklopedija.lv/skirklis/36628-aliter%C4%81cija,-stilistiska-fig%C5%Abra',
      letonikaLink:
        'https://letonika.lv/groups/default.aspx?r=4&q=aliter%C4%81cija&id=967548&g=1',
      readMoreText: 'aliterācju',
      isStylisticFigure: true,
    },
    {
      artisticExpressionTitle: 'Anafora',
      artisticExpressionDefinition:
        'Anafora – viena vai vairāku vārdu mērķtiecīgs atkārtojums vārsmas sākumā.',
      firstExample:
        'Un es saprotu, ka esmu muļķis palicis, — <br /> <span style="color:#DD0000;">vai tad par ko labu ir kas</span> jājautā, <br /> <span style="color:#DD0000;">vai tad par ko labu ir kas</span> jātirdī, <br /> vai tad vārdiem jābūt tur, kur jāklusē?',
      firstExampleAuthor: '/O.Vācietis "Prologs"/',
      firstExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=306&q=%22vai%20tad%20par%20ko%20labu%20ir%20kas%22#3111112/I:1/P:127',
      secondExample:
        '<span style="color:#DD0000;">Es atnācu še</span> atspirgšanu smelties — <br /> <span style="color:#DD0000;">Es atnācu še</span> — sevim kapa rakt...',
      secondExampleAuthor: '/V.Plūdonis "Atraitnes dēls"/',
      secondExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=348&q=%22Es%20atn%C4%81cu%20%C5%A1e%22#3338474/I:1/P:102',
      encyclopediaLink:
        'https://enciklopedija.lv/skirklis/3903-anafora,-stilistiska-fig%C5%Abra',
      letonikaLink:
        'https://letonika.lv/groups/default.aspx?r=4&q=anafora&id=967659&g=1',
      readMoreText: 'anaforu',
      isStylisticFigure: true,
    },
    {
      artisticExpressionTitle: 'Epifora',
      artisticExpressionDefinition:
        'Epifora – viena vai vairāku vārdu mērķtiecīgs atkārtojums vārsmas beigās.',
      firstExample:
        'Es nāvi redzēju... Tā lēni <span style="color:#DD0000;">līda</span> <br /> Gar manu gultu, kad es atnācu <br /> Šai namā. Lēni, lēni viņa <span style="color:#DD0000;">līda</span> <br /> Kā zaglis tumsā, nē, kā plēsīgs zvērs, <br /> Kas laupījumu glūn. Tas bija nakti.',
      firstExampleAuthor: '/V.Plūdonis "Atraitnes dēls"/',
      firstExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=348&q=%22T%C4%81%20l%C4%93ni%20l%C4%ABda%22#3338474/I:1/P:102',
      secondExample:
        'Simtiem aizspriedumu <br /> Mēs mākam sev līdzi vazāt, <br /> Lai tikai īstajā brīdī <br /> Noliktu savējos svaros, <br /> Lai tikai jāizdara ir <span style="color:#DD0000;">mazāk</span>, <br /> Lai tikai jāiztur <span style="color:#DD0000;">mazāk</span> <br /> Un lai pie visa vēl <br /> Būtu varoņi.',
      secondExampleAuthor: '/O.Vācietis "Uguns pielūgšana"/',
      secondExampleLink:
        'https://letonika.lv/literatura/Reader.aspx?r=307&q=maz%C4%81k#3111302/I:7/P:37',
      encyclopediaLink:
        'https://enciklopedija.lv/skirklis/3906-epifora,-stilistiska-fig%C5%Abra',
      letonikaLink:
        'https://letonika.lv/groups/default.aspx?r=4&q=epifora&id=967534&g=1',
      readMoreText: 'epiforu',
      isStylisticFigure: true,
    },
  ];

  loadGraph() {
    // create an array with nodes
    var nodes = new vis.DataSet([
      //Top node
      {
        id: 1,
        font: { multi: 'html' },
        label: '<b>VALODAS UN TĒLAINĪBAS LĪDZEKĻI</b> ',
        color: '#9BDD9C',
      },

      //sadalījums - Tropi
      {
        id: 2,
        font: { multi: true },
        label: '<b>TROPI</b>\n <i>vārdu lietojums pārnestā nozīmē</i>',
        color: '#7CD4E0',
      },
      //
      {
        id: 3,
        font: { multi: true },
        label:
          '<b>EPITETS</b> \n <i> sevišķi spilgts un svarīgs apzīmējums</i>',
        color: '#C2EBF7',
      },
      {
        id: 4,
        font: { multi: true },
        label:
          '<b>SALĪDZINĀJUMS</b> \n <i> divu līdzīgu priekšstatu saistījums</i>',
        color: '#C2EBF7',
      },
      {
        id: 5,
        font: { multi: true },
        label:
          '<b>METAFORA</b> \n <i>uz priekšstatu vai nozīmju līdzības pamata lietots vārds</i>',
        color: '#C2EBF7',
      },
      {
        id: 6,
        font: { multi: true },
        label:
          '<b>PERSONIFIKĀCIJA</b> \n <i>cilvēka īpašību vai spēju piedēvējums</i>',
        color: '#C2EBF7',
      },
      {
        id: 7,
        font: { multi: true },
        label:
          '<b>ALEGORIJA</b> \n <i>pārnestas nozīmes tēla lietojums cita tēla vietā</i>',
        color: '#C2EBF7',
      },
      {
        id: 8,
        font: { multi: true },
        label:
          '<b>SIMBOLS</b> \n <i>daudznozīmīgs tēls, kas nosacīti pauž ideju</i>',
        color: '#C2EBF7',
      },

      //sadalījums - Stilistiskās figūras
      {
        id: 9,
        font: { multi: true },
        label:
          '<b>STILISTISKĀS FIGŪRAS</b> \n<i>vārdi tēlainības un izteiksmības veidošanai</i>',
        color: '#EBF169',
      },
      //
      {
        id: 10,
        font: { multi: true },
        label: '<b>ASONANSE</b> \n <i>vienādu patskaņu atkārtojums  vārdos</i>',
        color: '#F4F3AF',
      },
      {
        id: 11,
        font: { multi: true },
        label:
          '<b>ALITERĀCIJA</b> \n <i>vienādu līdzskaņu atkārtojums  vārdos</i>',
        color: '#F4F3AF',
      },
      {
        id: 12,
        font: { multi: true },
        label: '<b>ANAFORA</b> \n <i>vārdu atkārtojums vārsmas sākumā</i>',
        color: '#F4F3AF',
      },
      {
        id: 13,
        font: { multi: true },
        label: '<b>EPIFORA</b> \n <i>vārdu atkārtojums vārsmas beigās</i>',
        color: '#F4F3AF',
      },
    ]);

    // create an array with edges
    var edges = new vis.DataSet([
      //
      {
        from: 1,
        to: 2,
        color: { inherit: 'both' },
        arrows: {
          to: { enabled: true, type: 'vee' },
        },
      },
      {
        from: 1,
        to: 9,
        color: { inherit: 'both' },
        arrows: {
          to: { enabled: true, type: 'vee' },
        },
      },
      //
      { from: 2, to: 3, color: { inherit: 'both' } },
      { from: 2, to: 4, color: { inherit: 'both' } },
      { from: 2, to: 5, color: { inherit: 'both' } },
      { from: 2, to: 6, color: { inherit: 'both' } },
      { from: 2, to: 7, color: { inherit: 'both' } },
      { from: 2, to: 8, color: { inherit: 'both' } },
      //
      { from: 9, to: 10, color: { inherit: 'both' } },
      { from: 9, to: 11, color: { inherit: 'both' } },
      { from: 9, to: 12, color: { inherit: 'both' } },
      { from: 9, to: 13, color: { inherit: 'both' } },
    ]);

    // create a network
    var container = document.getElementById('mynetwork');

    // provide the data in the vis format
    var data = {
      nodes: nodes,
      edges: edges,
    };
    var options = {
      edges: {
        arrows: {
          to: {
            enabled: true,
            imageHeight: undefined,
            imageWidth: undefined,
            scaleFactor: 1,
            src: undefined,
            type: 'arrow',
          },
        },

        font: {
          size: 12,
          bold: {
            color: '#0077aa',
          },
        },

        widthConstraint: {
          maximum: 3000,
        },
      },

      nodes: {
        shape: 'box',
        margin: 8,
        widthConstraint: {
          maximum: 150, //node width
        },
      },
      // interaction: { dragNodes: false },
      physics: {
        enabled: true, // if true, nodespacing unavailable
        hierarchicalRepulsion: {
          nodeDistance: 380,
        },
      },

      edgesStyle: {
        width: 2,
        shadow: true,
      },

      layout: {
        //randomSeed: undefined,
        // improvedLayout:true,
        // clusterThreshold: 150,
        hierarchical: {
          enabled: false,
          levelSeparation: 150, // between levels
          nodeSpacing: 180,
          treeSpacing: 100,
          blockShifting: true,
          edgeMinimization: false,
          parentCentralization: true,
          direction: 'UD', // UD, DU, LR, RL
          sortMethod: 'hubsize', // hubsize, directed
          shakeTowards: 'leaves', // roots, leaves
        },
      },
    };

    // initialize your network!
    var network = new vis.Network(container, data, options);
    network.on('click', (params: any) => {
      let nodeValue = params.nodes[0];

      switch (nodeValue) {
        // valodas un tēlainības līdzekļi
        case 1:
          (this.target as any)._results[0].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // tropi
        case 2:
          (this.target as any)._results[1].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // epitets
        case 3:
          (this.target as any)._results[2].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // salīdzinājums
        case 4:
          (this.target as any)._results[3].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // metafora
        case 5:
          (this.target as any)._results[4].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // personifikācija
        case 6:
          (this.target as any)._results[5].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // alegorija
        case 7:
          (this.target as any)._results[6].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // simbols
        case 8:
          (this.target as any)._results[7].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // stilistiskās figūras
        case 9:
          (this.target as any)._results[8].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // asonanse
        case 10:
          (this.target as any)._results[9].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // aliterācija
        case 11:
          (this.target as any)._results[10].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // anafora
        case 12:
          (this.target as any)._results[11].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
        // epifora
        case 13:
          (this.target as any)._results[12].nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          break;
      }
    });
  }

  constructor(public scriptsService: ScriptService) {
    this.scriptsService
      .load('valodasuntelainibasizteiksmeslidzekli')
      .then(() => {
        this.loadGraph();
      })
      .catch((error: any) => console.log(error));
  }

  ngOnInit(): void {}
}
