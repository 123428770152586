interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
  {
    name: 'valodasuntelainibasizteiksmeslidzekli',
    src: 'https://unpkg.com/vis-network/standalone/umd/vis-network.min.js',
  },
  {
    name: 'literaturasveidiunzanri',
    src: 'https://unpkg.com/vis-network/standalone/umd/vis-network.min.js',
  },
  {
    name: 'laikagrafs',
    src: 'https://unpkg.com/vis-timeline@latest/standalone/umd/vis-timeline-graph2d.min.js',
  },
  {
    name: 'webchat',
    src: 'assets/webchat.min.js',
  }
];
