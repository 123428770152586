<!-- carousel -->

<!-- start of carousel -->
<div id="main-page">
  <div class="row carousel-section">
    <div class="col">
      <div
        id="carouselExampleDark"
        class="carousel carousel-dark slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active va-first" data-bs-interval="5500">
            <object
              *ngIf="show"
              type="image/svg+xml"
              data="assets/images/Cibinsh_banner_1.svg"
              class="d-block cibins-banner"
            ></object>
            <div class="carousel-caption d-md-block">
              <p>
                Es sarunāšos ar tevi un pārbaudīšu zināšanas par literatūras
                žanriem un darbiem!
              </p>
              <h5>Cibiņš</h5>
            </div>
          </div>
          <div class="carousel-item va-second" data-bs-interval="5500">
            <object
              type="image/svg+xml"
              data="assets/images/Skroderiene_banner_6.svg"
              class="d-block skroderiene-banner"
            ></object>
            <div class="carousel-caption d-md-block">
              <p>
                No Atraitnes dēla līdz Skroderdienām Silmačos - lasi, klausies,
                izzini un darbojies!
              </p>
              <h5>Skroderiene</h5>
            </div>
          </div>
          <div class="carousel-item va-third" data-bs-interval="5500">
            <object
              type="image/svg+xml"
              data="assets/images/Zvejnieks_banner_1.svg"
              class="d-block zvejnieks-banner"
            ></object>
            <div class="carousel-caption d-md-block">
              <p>
                Ar mani kopā literatūru mācīties ir kā Zaļgas zivju vezumu
                izsalkumā notiesāt!
              </p>
              <h5>Zvejnieks</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col main-info">
    <h1 class="main-heading">Literatūra 7.-9.klasei</h1>

    <h3 class="second-heading-main-info">
      Mācies un pārbaudi zināšanas, veido radošos darbus!
    </h3>
  </div>

  <!-- end of caurousel -->

  <router-outlet></router-outlet>
</div>
